/*
@File: abaris Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - About Area CSS
** - Shop Section CSS
** - Video Section CSS
** - Fun Facts Section CSS
** - Summary Section CSS
** - Services Section CSS
** - Cultivation Section CSS
** - Services Section CSS
** - Cultivation Section CSS
** - Fisherman Section CSS
** - Cowman Section CSS
** - Testimonials Section CSS
** - Blog Section CSS
** - Partner Section CSS
** - Farm Area CS
** - Cow Section CSS
** - Faq Section CSS
** - Subscribe Area CSS
** - Page Title Area CSS
** - Team Section CSS
** - Gallery Area CSS
** - Login In CSS
** - Sign Up CSS
** - 404 Error Area CSS
** - Contact Section CSS
** - Services Details CSS
** - Sidebar Widget Area CSS
** - Blog Details Area CSS
** - Pagination Area CSS
** - Footer Section CSS
** - Copyright Area CSS

*/
/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #333333;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  text-decoration: none !important;
  outline: 0 !important;
  display: inline-block;
}

ul {
  list-style: none;
}

a:hover {
  color: #209e2e;
  text-decoration: none;
}

p {
  line-height: 1.8;
  color: #7a7e9a;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
}

p:last-child {
  margin-bottom: 0;
}

button {
  outline: 0 !important;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
  font-weight: bold;
}

.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #ffffff;
  text-transform: capitalize;
  background-color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.default-btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #333333;
  -webkit-transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
  transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px;
}

.default-btn:hover {
  color: #ffffff;
}

.default-btn:hover span {
  width: 225%;
  height: 562.5px;
}

.default-btn i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.optional-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  background-color: #209e2e;
  cursor: pointer;
  transition: 0.6s;
  font-size: 16px;
  padding: 8px 30px;
  font-weight: 600;
}

.optional-btn i {
  margin-right: 2px;
  font-size: 18px;
}

.optional-btn:hover,
.optional-btn:focus {
  color: #ffffff !important;
  background-color: #209e2e;
}

.optional-btn i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
}

.section-title span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
  font-weight: 400;
}

.section-title h3 {
  font-size: 38px;
  margin: 10px 0 16px 0;
}

.section-title p {
  max-width: 605px;
  margin: auto;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 99999;
}

.preloader .lds-ripple {
  position: relative;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader .lds-ripple div {
  position: absolute;
  border: 4px solid #209e2e;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.preloader .lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/*================================================
Navbar Area CSS
=================================================*/
.abaris-responsive-nav {
  display: none;
}

.abaris-nav {
  background-color: transparent;
}

.abaris-nav .navbar {
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 5px;
  padding-right: 25px;
  padding-top: 0;
  padding-left: 25px;
  padding-bottom: 0;
}

.abaris-nav .navbar .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand-md .navbar-nav {
  justify-content: center;
  width: 100%;
}

div#navbarSupportedContent {
  position: relative;
}

.abaris-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.abaris-nav .navbar .navbar-nav {
  margin-right: auto;
  margin-left: auto;
}

.abaris-nav .navbar .navbar-nav .nav-item {
  padding: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item a {
  font-size: 14px;
  color: #333333;
  text-transform: uppercase;
  padding: 20px 0 10px;
  margin: 0 15px;
  font-weight: 500;
}

.abaris-nav .navbar .navbar-nav .nav-item a:hover,
.abaris-nav .navbar .navbar-nav .nav-item a:focus,
.abaris-nav .navbar .navbar-nav .nav-item a.active {
  color: #209e2e;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

/* .abaris-nav .navbar .navbar-nav .nav-item a:hover::before, .abaris-nav .navbar .navbar-nav .nav-item a:focus::before, .abaris-nav .navbar .navbar-nav .nav-item a.active::before {
  content: "";
  position: absolute;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #209e2e;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 30px;
  bottom: 20px;
} */
.abaris-nav .navbar .navbar-nav .nav-item a i {
  font-size: 16px;
}

.abaris-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item:hover a,
.abaris-nav .navbar .navbar-nav .nav-item.active a {
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 6px 0;
  margin: 0;
  position: relative;
  color: #333333;
  font-size: 15.5px;
  font-weight: 400;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus::before,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  display: none;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #209e2e;
}

.abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.abaris-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  margin-top: 0;
}

.abaris-nav .navbar .others-options {
  margin-left: 0;
}

.abaris-nav .navbar .others-options .option-item {
  color: #333333;
  display: inline-block;
  position: relative;
  line-height: 1;
}

.abaris-nav .navbar .others-options .option-item .search-btn {
  display: block;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .option-item .search-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .option-item .search-btn i {
  font-size: 16px;
}

.abaris-nav .navbar .others-options .option-item .close-btn {
  display: none;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .option-item .close-btn.active {
  display: block;
}

.abaris-nav .navbar .others-options .option-item .close-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .option-item .close-btn i {
  font-size: 16px;
}

.abaris-nav .navbar .others-options .cart-btn {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .cart-btn i {
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .cart-btn a {
  color: #b7b7b7;
  position: relative;
}

.abaris-nav .navbar .others-options .cart-btn a span {
  position: absolute;
  right: -5px;
  bottom: 12px;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
  background: #209e2e;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .cart-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .cart-btn:hover a:hover i {
  color: #ffffff;
}

.abaris-nav .navbar .others-options .cart-btn:hover a span {
  background: #ffffff;
  color: #209e2e;
}

.abaris-nav .navbar .others-options .burger-menu {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.abaris-nav .navbar .others-options .burger-menu:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.abaris-nav .navbar .others-options .burger-menu i::before {
  line-height: normal;
  font-size: 16px;
}

.navbar-area {
  position: absolute;
  left: 0;
  top: 35px;
  width: 100%;
  height: auto;
  z-index: 999;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-area.is-sticky .abaris-nav .navbar {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-left: 0;
  padding-right: 0;
}

.navbar-area.navbar-style-two {
  border-bottom: 1px solid #5d5649;
  top: 0;
  padding-left: 40px;
  padding-right: 40px;
}

.navbar-area.navbar-style-two .abaris-nav {
  background-color: transparent;
}

.navbar-area.navbar-style-two .abaris-nav .navbar {
  background-color: transparent;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: -70px;
  height: 100%;
  width: 1px;
  background-color: #5d5649;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav::after {
  content: "";
  position: absolute;
  top: 0;
  right: -65px;
  height: 100%;
  width: 1px;
  background-color: #5d5649;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a {
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item:hover a,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #333333;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus,
.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #209e2e;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options {
  margin-left: 0;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item {
  color: #333333;
  display: inline-block;
  position: relative;
  line-height: 1;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .search-btn {
  display: block;
  color: #ffffff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .search-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .search-btn i {
  font-size: 16px;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn {
  display: none;
  color: #ffffff;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn.active {
  display: block;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .option-item .close-btn i {
  font-size: 16px;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn a {
  color: #b7b7b7;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn a:hover {
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn:hover a:hover i {
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .cart-btn i {
  font-size: 16px;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .burger-menu {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #b7b7b7;
  cursor: pointer;
  color: #b7b7b7;
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 1px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .burger-menu:hover {
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
}

.navbar-area.navbar-style-two .abaris-nav .navbar .others-options .burger-menu i::before {
  line-height: normal;
  font-size: 16px;
}

.navbar-area.navbar-style-two.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two {
  border-bottom: none;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav {
  position: relative;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav::before {
  display: none;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav::after {
  display: none;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item {
  position: relative;
  padding: 0;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a {
  color: #333333;
}

.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a:hover,
.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a:focus,
.navbar-area.navbar-style-two.is-sticky.navbar-style-two .abaris-nav .navbar-nav .nav-item a.active {
  color: #209e2e;
}

.navbar-area-three {
  position: relative;
  background-color: transparent;
}

.navbar-area-three.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-area-three.is-sticky .abaris-nav .navbar {
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-left: 0;
  padding-right: 0;
}

.navbar-area-three .abaris-nav .navbar {
  background-color: #ffffff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.search-overlay {
  display: none;
}

.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  right: 0;
  background: #ffffff;
  z-index: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.search-overlay.search-popup .search-form {
  position: relative;
}

.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #333333;
  outline: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding-top: 4px;
  padding-left: 10px;
}

.search-overlay.search-popup .search-form .search-input:focus {
  border-color: #209e2e;
}

.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: transparent;
  border: none;
  width: 50px;
  outline: 0;
  color: #7a7e9a;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding: 0;
}

.search-overlay.search-popup .search-form .search-button:hover,
.search-overlay.search-popup .search-form .search-button:focus {
  color: #209e2e;
}

.sidebar-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  overflow: hidden;
}

.sidebar-modal .sidebar-modal-inner {
  position: absolute;
  right: -100%;
  top: 0;
  width: 30%;
  overflow-y: scroll;
  height: 100%;
  background-color: #ffffff;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.sidebar-modal .sidebar-modal-inner .close-btn {
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 20px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #333333;
  opacity: 0.66;
  cursor: pointer;
}

.sidebar-modal .sidebar-modal-inner .close-btn:hover {
  opacity: 1;
}

.sidebar-modal .sidebar-about-area {
  margin-bottom: 40px;
}

.sidebar-modal .sidebar-about-area .title h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
}

.sidebar-modal .sidebar-about-area .title p {
  margin-top: 15px;
  font-size: 15px;
}

.sidebar-modal .sidebar-instagram-feed {
  margin-bottom: 40px;
}

.sidebar-modal .sidebar-instagram-feed h2 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
}

.sidebar-modal .sidebar-instagram-feed ul {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 0;
  margin-top: -10px;
}

.sidebar-modal .sidebar-instagram-feed ul li {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.sidebar-modal .sidebar-instagram-feed ul li a {
  display: block;
  position: relative;
}

.sidebar-modal .sidebar-instagram-feed ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-modal .sidebar-instagram-feed ul li a:hover::before {
  opacity: 0.5;
  visibility: visible;
}

.sidebar-modal .sidebar-contact-area .contact-info {
  text-align: center;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a {
  display: inline-block;
  color: #209e2e;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:hover {
  color: #333333;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child) {
  color: #333333;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 a:not(:first-child):hover {
  color: #209e2e;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content h2 span {
  display: block;
  color: #7a7e9a;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li {
  display: inline-block;
  margin: 0 1px;
  padding-left: 0;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a {
  width: 35px;
  height: 35px;
  line-height: 34px;
  border: 1px solid #dadada;
  border-radius: 50%;
  color: #aba5a5;
  display: block;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a i {
  font-size: 14px;
}

.sidebar-modal .sidebar-contact-area .contact-info .contact-info-content .social li a:hover {
  color: #ffffff;
  border-color: #209e2e;
  background-color: #209e2e;
}

.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
}

.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}

@media only screen and (max-width: 991px) {
  .abaris-responsive-nav {
    display: block;
  }

  .abaris-responsive-nav .abaris-responsive-menu {
    position: relative;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .mean-nav ul li a.active {
    color: #209e2e;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14px;
  }

  .abaris-responsive-nav .abaris-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 336px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  .abaris-responsive-nav .mean-container a.meanmenu-reveal {
    color: #333333;
  }

  .abaris-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #333333;
    position: relative;
    top: 8px;
    margin-top: -5.8px;
    border-radius: 5px;
  }

  .abaris-responsive-nav .mean-container .others-option {
    display: none;
  }

  .abaris-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }

  .navbar-area {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navbar-area.navbar-style-two {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-area.navbar-style-two.is-sticky {
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff !important;
  }

  /* .abaris-nav {
    display: none;
  } */
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 950px;
}

.main-banner-content {
  max-width: 630px;
  margin-left: auto;
}

.main-banner-content h1 {
  font-size: 52px;
  color: #333333;
  margin: 0 0 16px 0;
}

.main-banner-content p {
  color: #7a7e9a;
  margin: 0 0 0 0;
}

.main-banner-content .banner-btn {
  margin-top: 35px;
}

.main-banner-content .banner-list {
  padding-left: 0;
  margin-bottom: 20px;
  position: relative;
}

.main-banner-content .banner-list li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  position: relative;
  border-right: 1px dotted #d0ebd3;
  padding: 0 20px;
}

.main-banner-content .banner-list li:first-child {
  padding-left: 0;
}

.main-banner-content .banner-list li:last-child {
  border-right: none;
}

.main-banner-content .banner-list i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #eefbf2;
  color: #209e2e;
  border-radius: 30px;
  border: 2px dotted #d0eed6;
  font-weight: 500;
}

.main-banner-content .banner-list i::before {
  font-size: 25px;
}

.main-banner-content .banner-list sup {
  bottom: 0;
  position: absolute;
  right: 26px;
  background-color: #209e2e;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff;
}

.main-banner-content .banner-list span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1;
}

.banner-image {
  text-align: right;
  margin-top: -10px;
  position: relative;
}

.banner-image .shape-area .creative-shape {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  left: -4%;
}

.banner-image .shape-area .creative-shape-two {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  left: -1%;
}

.shape-img1 {
  position: absolute;
  left: 40%;
  bottom: 10%;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.shape-img2 {
  position: absolute;
  left: 10px;
  top: 0;
  z-index: -1;
  -webkit-animation: moveScale 3s linear infinite;
  animation: moveScale 3s linear infinite;
}

.shape-img3 {
  position: absolute;
  left: 0;
  bottom: 15%;
  z-index: -1;
  -webkit-animation: moveBounce 5s linear infinite;
  animation: moveBounce 5s linear infinite;
}

.shape-img4 {
  position: absolute;
  left: 35%;
  right: auto;
  top: 14%;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.shape-img5 {
  position: absolute;
  left: 20%;
  right: auto;
  top: 40%;
  z-index: -1;
  -webkit-animation: animationFramesOne 10s infinite linear;
  animation: animationFramesOne 10s infinite linear;
}

@-webkit-keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-73px, 1px) rotate(-36deg);
    transform: translate(-73px, 1px) rotate(-36deg);
  }

  40% {
    -webkit-transform: translate(-141px, -72px) rotate(-72deg);
    transform: translate(-141px, -72px) rotate(-72deg);
  }

  60% {
    -webkit-transform: translate(-83px, -122px) rotate(-108deg);
    transform: translate(-83px, -122px) rotate(-108deg);
  }

  80% {
    -webkit-transform: translate(40px, -72px) rotate(-144deg);
    transform: translate(40px, -72px) rotate(-144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(-73px, 1px) rotate(-36deg);
    transform: translate(-73px, 1px) rotate(-36deg);
  }

  40% {
    -webkit-transform: translate(-141px, -72px) rotate(-72deg);
    transform: translate(-141px, -72px) rotate(-72deg);
  }

  60% {
    -webkit-transform: translate(-83px, -122px) rotate(-108deg);
    transform: translate(-83px, -122px) rotate(-108deg);
  }

  80% {
    -webkit-transform: translate(40px, -72px) rotate(-144deg);
    transform: translate(40px, -72px) rotate(-144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes moveScale {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@keyframes moveScale {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

@-webkit-keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveBounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes moveBounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*================================================
Home Banner Two Area CSS
=================================================*/
.home-banner-two {
  position: relative;
  overflow: hidden;
}

.home-banner-two .home-slides {
  position: relative;
}

.home-banner-two .home-slides .home-item {
  position: relative;
  height: 910px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  overflow: hidden;
}

.home-banner-two .home-slides .home-item::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000000;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.7;
}

.home-banner-two .home-slides .home-item.item-bg1 {
  background-image: url(../../assets/img/home-two/bg-1.jpg);
}

.home-banner-two .home-slides .home-item.item-bg2 {
  background-image: url(../../assets/img/home-two/bg-2.jpg);
}

.home-banner-two .home-slides .home-item.item-bg3 {
  background-image: url(../../assets/img/home-two/bg-3.jpg);
}

.home-banner-two .home-slides .home-item .main-banner-content {
  max-width: 630px;
  margin-right: auto;
  margin-left: unset;
}

.home-banner-two .home-slides .home-item .main-banner-content h1 {
  font-size: 55px;
  color: #ffffff;
  margin: 0 0 16px 0;
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content p {
  color: #ffffff;
  margin: 0 0 0 0;
  -webkit-animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn {
  margin-top: 35px;
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn .default-btn {
  border: none;
  padding: 14px 30px;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn .optional-btn {
  border: none;
  padding: 14px 30px;
  background-color: #ffffff;
  color: #209e2e;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-btn .optional-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list {
  padding-left: 0;
  margin-bottom: 20px;
  position: relative;
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  position: relative;
  border-right: 1px dashed #d0ebd3;
  padding: 0 20px;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list li:first-child {
  padding-left: 0;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list li:last-child {
  border-right: none;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #eefbf2;
  color: #209e2e;
  border-radius: 30px;
  border: 2px dotted #d0eed6;
  font-weight: 500;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list i::before {
  font-size: 25px;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list sup {
  bottom: 0;
  position: absolute;
  right: 26px;
  background-color: #209e2e;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff;
}

.home-banner-two .home-slides .home-item .main-banner-content .banner-list span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1;
}

.home-banner-two .home-slides .home-item .main-banner-content b {
  color: #209e2e;
}

.home-banner-two .home-slides.owl-theme .owl-dots {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 80px;
  display: -ms-grid;
  display: grid;
}

.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: relative;
}

.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.home-banner-two .home-slides.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-banner-two .banner-social-buttons {
  position: absolute;
  left: 30px;
  top: 60%;
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: translate(-100%, -100%) rotate(90deg);
  transform: translate(-100%, -100%) rotate(90deg);
  z-index: 1;
}

.home-banner-two .banner-social-buttons ul {
  padding-left: 0;
  margin-bottom: 0;
}

.home-banner-two .banner-social-buttons ul li {
  color: #ffffff;
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}

.home-banner-two .banner-social-buttons ul li:last-child {
  margin-right: 0;
}

.home-banner-two .banner-social-buttons ul a {
  color: #ffffff;
}

.home-banner-two .banner-social-buttons ul a:hover {
  color: #209e2e;
}

.home-banner-two .banner-social-buttons ul i {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transform: translate(-50%, -50%) rotate(270deg);
  transform: translate(-50%, -50%) rotate(270deg);
}

.home-banner-two .creative-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.home-banner-two .shape-img1 {
  position: absolute;
  right: 30px;
  left: auto;
  bottom: 46px;
  z-index: 1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.home-banner-two .shape-img2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  -webkit-animation: moveScale 3s linear infinite;
  animation: moveScale 3s linear infinite;
}

/*================================================
Home Banner Three Area CSS
=================================================*/
.home-banner-three {
  position: relative;
}

.home-banner-three .home-slides {
  position: relative;
}

.home-banner-three .home-slides .home-item {
  position: relative;
  height: 910px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  overflow: hidden;
}

.home-banner-three .home-slides .home-item::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

.home-banner-three .home-slides .home-item.item-bg {
  background-image: url(../../assets/img/home-three/bg.png);
}

.home-banner-three .home-slides .home-item .main-banner-content {
  max-width: 630px;
  margin-left: auto;
  margin-right: 0;
}

.home-banner-three .home-slides .home-item .main-banner-content h1 {
  font-size: 55px;
  color: #333333;
  margin: 0 0 16px 0;
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content p {
  color: #333333;
  margin: 0 0 0 0;
  -webkit-animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn {
  margin-top: 35px;
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn .default-btn {
  border: none;
  padding: 14px 30px;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn .optional-btn {
  border: none;
  padding: 14px 30px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-btn .optional-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list {
  padding-left: 0;
  margin-bottom: 20px;
  position: relative;
  -webkit-animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
  animation: 900ms ease-in-out 0s normal none 1 running fadeInLeft;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  position: relative;
  border-right: 1px dashed #d0ebd3;
  padding: 0 20px;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list li:first-child {
  padding-left: 0;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list li:last-child {
  border-right: none;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #eefbf2;
  color: #209e2e;
  border-radius: 30px;
  border: 2px dotted #d0eed6;
  font-weight: 500;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list i::before {
  font-size: 25px;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list sup {
  bottom: 0;
  position: absolute;
  right: 26px;
  background-color: #209e2e;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff;
}

.home-banner-three .home-slides .home-item .main-banner-content .banner-list span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
  margin-top: 12px;
  line-height: 1;
}

.home-banner-three .home-slides .home-item .main-banner-content strong {
  color: #209e2e;
}

.home-banner-three .home-slides.owl-theme .owl-dots {
  margin: 0 !important;
  position: absolute;
  bottom: 10%;
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
  right: 0;
  left: 0;
  display: -ms-grid;
  display: inline-block;
}

.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
  border: 1px solid #209e2e;
  position: relative;
}

.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #209e2e;
  border-radius: 50%;
  margin: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.home-banner-three .home-slides.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-banner-three .creative-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

/*================================================
About Section CSS
=================================================*/
.about-section {
  position: relative;
}

.about-section.bg-f4faf4 {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #f4faf4;
}

.about-section.section-bottom {
  padding-bottom: 175px;
}

.about-title-area {
  margin-bottom: 60px;
}

.about-title-area .about-title {
  border-right: 1px solid #dfdddf;
  padding-right: 35px;
}

.about-title-area .about-title span {
  color: #209e2e;
  font-size: 15px;
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.about-title-area .about-title h2 {
  margin-bottom: 0;
  font-size: 38px;
  font-weight: 700;
}

.about-title-area .about-text {
  padding-left: 35px;
}

.about-title-area .about-text p {
  margin-bottom: 0;
}

.about-title-area .about-text .read-more-btn {
  margin-top: 18px;
  display: inline-block;
  color: #209e2e;
  font-size: 16px;
  font-weight: 600;
}

.about-title-area .about-text .read-more-btn i {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  display: inline-block;
}

.about-title-area .about-text .read-more-btn:hover i {
  margin-left: 3px;
}

.about-slider .about-item .about-text {
  padding: 20px;
  background-color: #fdfdfd;
  border-left: 1px dashed #8fe2aa;
  border-right: 1px dashed #8fe2aa;
  border-bottom: 1px dashed #8fe2aa;
  border-radius: 0 0 5px 5px;
  line-height: 1;
}

.about-slider .about-item .about-text span {
  font-size: 15px;
  color: #209e2e;
  position: relative;
  padding-left: 18px;
}

.about-slider .about-item .about-text span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #209e2e;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.about-slider .about-item .about-text h3 {
  font-size: 16px;
  margin: 10px 0 0 0;
  line-height: 22px;
}

.about-slider.owl-theme .owl-nav {
  opacity: 0;
  visibility: hidden;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.about-slider.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  left: -16px;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
}

.about-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: -16px;
}

.about-slider.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.about-slider.owl-theme .owl-dots {
  line-height: 0.01;
  margin-top: 30px;
  margin-bottom: 0;
}

.about-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 0 4px;
  background-color: #333333;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 30px;
}

.about-slider.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #209e2e;
}

.about-slider.owl-theme .owl-dots .owl-dot.active span {
  background-color: #209e2e;
}

.about-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.about-content-area .about-content {
  margin-top: 20px;
}

.about-content-area .about-content h3 {
  font-size: 25px;
  margin: 0 0 10px 0;
}

.about-content-area .about-content p {
  margin: 0 0 0 0;
}

.about-content-area .about-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.about-content-area .about-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.about-content-area .about-list i {
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 6px;
}

.about-shape-image {
  position: absolute;
  top: 220px;
  z-index: -1;
}

.default-shape .default-shape-one {
  position: absolute;
  bottom: 0;
}

.default-shape .default-shape-two {
  position: absolute;
  top: 0;
}

/*================================================
Harvesting Section CSS
=================================================*/
.harvesting-section {
  background-image: url(../../assets/img/harvesting/harvesting-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.harvesting-title {
  text-align: center;
  margin-bottom: 50px;
}

.harvesting-title span {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffff;
}

.harvesting-title h3 {
  font-size: 38px;
  color: #ffffff;
  margin: 12px 0 0 0;
}

.harvesting-item {
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 5px;
  margin-top: -30px;
  top: 30px;
}

.harvesting-item .harvesting-image {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  text-align: center;
  position: relative;
}

.harvesting-item .harvesting-image img {
  display: inline-block;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.harvesting-item .harvesting-image img:last-child {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}

.harvesting-item .harvesting-content h3 {
  font-size: 26px;
  color: #209e2e;
  margin: 20px 0 0 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.harvesting-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-radius: 5px;
  background-color: #209e2e;
  z-index: -1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.harvesting-item:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.harvesting-item:hover .icon i {
  color: #ffffff;
}

.harvesting-item:hover h3 {
  color: #ffffff;
}

.harvesting-item:hover i {
  color: #ffffff;
}

.harvesting-item:hover::before {
  height: 100%;
}

.harvesting-item:hover .harvesting-image img {
  opacity: 0;
}

.harvesting-item:hover .harvesting-image img:last-child {
  opacity: 1;
}

/*================================================
Shop Section CSS
=================================================*/
.shop-section.section-top {
  padding-top: 130px;
}

.tab .tabs_item {
  display: none;
}

.tab .tabs_item:first-child {
  display: block;
}

.shop-list-tab .tabs {
  padding-left: 0;
  margin-bottom: 50px;
  text-align: center;
}

.shop-list-tab .tabs li {
  text-align: center;
  display: inline-block;
  list-style: none;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-right: 1px dashed #209e2e;
}

.shop-list-tab .tabs li:first-child {
  padding-left: 0;
}

.shop-list-tab .tabs li:last-child {
  border: none;
  padding-right: 0;
}

.shop-list-tab .tabs li a {
  display: block;
  border-radius: 2px;
  color: #8d8c8c;
  font-size: 18px;
  font-weight: 400;
}

.shop-list-tab .tabs li a span {
  display: block;
  margin-top: 0;
}

.shop-list-tab .tabs li.current a,
.shop-list-tab .tabs li:hover a {
  color: #209e2e;
}

.shop-list-tab .tabs.tabs-two {
  border: 1px dashed #209e2e;
  max-width: 440px;
  margin: 0px auto 40px;
  padding: 16px 0;
  border-radius: 5px;
}

.shop-list-tab .tab_content .tabs_item .shop-item {
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px dashed #209e2e;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 30px;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 40px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #eeeded;
  left: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: -1;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #eeeded;
  right: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: -1;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-list i {
  color: #f4a708;
  font-size: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 12px 0 10px 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content span {
  font-size: 18px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn {
  margin-top: 15px;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn .shop-btn-one {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 30px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 50px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn .shop-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 0;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item .shop-content .shop-btn .shop-btn-one:hover {
  background-color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-radius: 5px;
  background-color: #209e2e;
  z-index: -1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover {
  border: 1px dashed #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover i {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover h3 {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover span {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover i {
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item:hover::before {
  height: 55%;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two {
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px dashed #209e2e;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background-color: #d2ecd5;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-content {
  margin-top: 30px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 0 0 10px 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-content span {
  font-size: 18px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  left: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  right: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-list i {
  color: #f4a708;
  font-size: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon li {
  display: inline-block;
  list-style-type: none;
  margin-right: 5px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon li:last-child {
  margin-right: 0;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon .big-icon i {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two .shop-icon .big-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover {
  border: 1px dashed #209e2e;
  background-color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover h3 {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover span {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-two:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three {
  padding: 30px 50px;
  border-radius: 5px;
  border: 1px dashed #209e2e;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background-color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-content {
  margin-top: 30px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 0 0 10px 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-content span {
  font-size: 18px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  left: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 75px;
  background-color: #209e2e !important;
  right: 0;
  top: 10px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-list i {
  color: #f4a708;
  font-size: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon li {
  display: inline-block;
  list-style-type: none;
  margin-right: 5px;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon li:last-child {
  margin-right: 0;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon .big-icon i {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: #eafef1;
  border: 1px solid #209e2e;
  border-radius: 30px;
  font-size: 14px;
  color: #209e2e;
  font-weight: bold;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three .shop-icon .big-icon i:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover {
  border: 1px dashed #209e2e;
  background-color: #d2ecd5;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover h3 {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover span {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .shop-item-three:hover i {
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .product-btn {
  text-align: center;
  margin-top: 40px;
}

.shop-list-tab .tab_content .tabs_item .product-btn .product-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
  border-radius: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.shop-list-tab .tab_content .tabs_item .product-btn .product-btn-one:hover {
  background-color: #ffffff;
  color: #209e2e;
}

.shop-list-tab .tab_content .tabs_item .product-btn .product-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

/*================================================
Video Section CSS
=================================================*/
.video-content {
  text-align: center;
  background-image: url(../../assets/img/video-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

.video-content .video-btn {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #209e2e;
  position: relative;
  z-index: 1;
}

.video-content .video-btn i {
  font-size: 30px;
  position: relative;
  top: 2px;
  left: 4px;
}

.video-content .video-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.video-content .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #ffffff;
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.video-content .video-btn:hover,
.video-content .video-btn .video-content .video-btn:focus {
  background-color: #209e2e;
  color: #ffffff;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

/*================================================
Fun Facts Section CSS
=================================================*/
.fun-facts-content-area {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 50px;
}

.fun-facts-content-area .col-lg-3:last-child .single-fun-fact {
  border-right: none;
}

.fun-facts-content-area .single-fun-fact {
  text-align: center;
  position: relative;
  border-right: 1px dashed #209e2e;
}

.fun-facts-content-area .single-fun-fact h3 {
  position: relative;
  color: #209e2e;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 50px;
  font-weight: 300;
}

.fun-facts-content-area .single-fun-fact h3 .sign-icon {
  display: inline-block;
  font-size: 50px;
  position: relative;
  top: 2px;
  right: 10px;
}

.fun-facts-content-area .single-fun-fact p {
  line-height: initial;
  color: #209e2e;
  margin: 0 0 0 0;
  font-weight: 400;
  font-size: 16px;
}

.fun-facts-content-area.fun-facts-top {
  position: relative;
  z-index: 1;
  margin-top: -85px;
}

/*================================================
Summary Section CSS
=================================================*/
.summary-section {
  position: relative;
}

.summary-content span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
}

.summary-content h3 {
  font-size: 38px;
  margin: 16px 0 20px 0;
}

.summary-content .summary-btn {
  margin-top: 30px;
}

.summary-content .summary-btn .optional-btn {
  margin-left: 0;
}

.summary-shape-image {
  position: absolute;
  bottom: 55px;
  z-index: -1;
}

.summary-shape-image-two {
  position: absolute;
  bottom: 55px;
  z-index: -1;
  right: 0;
}

/*================================================
Services Section CSS
=================================================*/
.services-content-area {
  position: relative;
}

.services-content-area .services-slider .services-item {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.services-content-area .services-slider .services-item .icon i {
  font-size: 70px;
  color: #209e2e;
  line-height: normal;
}

.services-content-area .services-slider .services-item h3 {
  font-size: 18px;
  color: #616161;
  margin: 2px 0 0 0;
  position: relative;
  font-weight: 500;
  z-index: 1;
}

.services-content-area .services-slider .services-item h3::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #209e2e;
  left: 0;
  right: 0;
  bottom: -25px;
  margin: auto;
  border-radius: 30px;
}

.services-content-area .services-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.services-content-area .services-slider.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  left: -16px;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
}

.services-content-area .services-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: -16px;
}

.services-content-area .services-slider.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.services-content-area .services-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.services-content-area .services-slider.owl-carousel .owl-stage-outer {
  padding-bottom: 5px;
}

.services-content-area .services-slider.owl-carousel .owl-stage-outer .owl-stage {
  border-bottom: 1px dashed #209e2e;
}

/*================================================
Cultivation Section CSS
=================================================*/
.cultivation-section {
  background-color: #eef7ef;
}

.single-cultivation {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-cultivation .cultivation-image {
  position: relative;
  border-radius: 50%;
}

.single-cultivation .cultivation-image img {
  border-radius: 50%;
  width: 100%;
}

.single-cultivation .cultivation-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: #209e2e;
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  visibility: hidden;
}

.single-cultivation .cultivation-image .details-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 70%;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  z-index: 2;
  color: #209e2e;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #eafef1;
  margin: 0 auto;
  line-height: 40px;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
}

.single-cultivation .cultivation-image .details-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
  border-color: #209e2e;
}

.single-cultivation .cultivation-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 80px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-cultivation .cultivation-content h3 {
  margin-bottom: 0;
  font-size: 25px;
  color: #ffffff;
  font-weight: 500;
}

.single-cultivation .cultivation-content span {
  display: block;
  color: #827f8d;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 5px;
}

.single-cultivation:hover .cultivation-image::before {
  opacity: 0.6;
  visibility: visible;
}

.single-cultivation:hover .cultivation-image .details-btn {
  opacity: 1;
  visibility: visible;
}

.single-cultivation:hover .cultivation-content {
  bottom: 140px;
}

/*================================================
Fisherman Section CSS
=================================================*/
.fisherman-content span {
  font-size: 15px;
  color: #209e2e;
  text-transform: capitalize;
}

.fisherman-content h3 {
  font-size: 30px;
  margin: 0;
  color: #454545;
  text-transform: uppercase;
}

.fisherman-content p {
  margin: 0 0 0 0;
}

.fisherman-content .fisherman-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.fisherman-content .fisherman-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.fisherman-content .fisherman-list i {
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 6px;
}

.fisherman-content .fisherman-btn {
  margin-top: 35px;
}

.fisherman-content .fisherman-btn .optional-btn {
  margin-left: 0;
}

.fisherman-image img {
  width: 100%;
}

/*================================================
Cowman Section CSS
=================================================*/
.cowman-content span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
}

.cowman-content h3 {
  font-size: 40px;
  margin: 16px 0 20px 0;
}

.cowman-content p {
  margin: 0 0 0 0;
}

.cowman-content .cowman-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.cowman-content .cowman-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.cowman-content .cowman-list i {
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 6px;
}

.cowman-content .cowman-btn {
  margin-top: 35px;
}

.cowman-content .cowman-btn .optional-btn {
  margin-left: 0;
}

.cowman-image img {
  width: 100%;
}

/*================================================
Testimonials Section CSS
=================================================*/
.testimonials-section {
  position: relative;
  background-image: url(../../assets/img/testimonials-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.testimonials-section::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: -1;
}

.testimonials-title {
  text-align: center;
  margin-bottom: 50px;
}

.testimonials-title span {
  font-size: 15px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
}

.testimonials-title h3 {
  font-size: 38px;
  color: #ffffff;
  max-width: 430px;
  margin: 12px auto 0;
}

.testimonials-slider {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.testimonials-slider .testimonials-item {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px dashed #209e2e;
  position: relative;
}

.testimonials-slider .testimonials-item .testimonials-content h3 {
  font-size: 20px;
  color: #616161;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.testimonials-slider .testimonials-item .testimonials-content span {
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
}

.testimonials-slider .testimonials-item .testimonials-content p {
  font-size: 16px;
  margin: 16px 0 0 0;
}

.testimonials-slider .testimonials-info {
  position: relative;
  margin-top: 30px;
}

.testimonials-slider .testimonials-info img {
  display: inline-block;
  width: 100px;
  border-radius: 50px;
}

.testimonials-slider .testimonials-info .icon {
  font-size: 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #e8f5ea;
  text-align: center;
  border-radius: 50px;
  color: #209e2e;
}

.testimonials-slider .testimonials-info .star-list {
  padding-left: 0;
  margin-bottom: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  display: inline-block;
  padding: 5px;
  list-style-type: none;
  border-radius: 5px;
  border-radius: 4px;
  position: relative;
  left: -28px;
  top: 20px;
}

.testimonials-slider .testimonials-info .star-list li {
  display: inline-block;
}

.testimonials-slider .testimonials-info .star-list li i {
  color: #f4a708;
  font-size: 12px;
}

.testimonials-slider .owl-item.active.center .testimonials-item {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.testimonials-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.testimonials-slider.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  left: 12%;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 18px;
  border-radius: 50px;
}

.testimonials-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: 12%;
}

.testimonials-slider.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.testimonials-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

/*================================================
Blog Section CSS
=================================================*/
.single-blog-item {
  text-align: left;
  padding: 30px;
  margin-bottom: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #209e2e;
  opacity: 1;
  z-index: -1;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  border-radius: 5px;
}

.single-blog-item.bg-1 {
  background-image: url(../../assets/img/blog/1.jpg);
}

.single-blog-item.bg-2 {
  background-image: url(../../assets/img/blog/2.jpg);
}

.single-blog-item.bg-3 {
  background-image: url(../../assets/img/blog/3.jpg);
}

.single-blog-item span {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  padding-left: 18px;
}

.single-blog-item span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.single-blog-item h3 {
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0 14px 0;
  font-weight: 500;
  line-height: 1.4;
}

.single-blog-item p {
  color: #ffffff;
  margin: 0 0 0 0;
}

.single-blog-item .blog-btn {
  margin-top: 20px;
}

.single-blog-item .blog-btn .blog-btn-one {
  display: inline-block;
  text-align: center;
  z-index: 1;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item .blog-btn .blog-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-blog-item .blog-btn .blog-btn-one:hover {
  background-color: #209e2e;
  color: #ffffff !important;
}

.single-blog-item:hover::before {
  opacity: 0.4;
}

.single-blog-item:hover .blog-btn-one {
  background-color: #209e2e;
  color: #ffffff !important;
}

.single-blog-item-two {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 30px;
}

.single-blog-item-two .blog-image {
  position: relative;
}

.single-blog-item-two .blog-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.5;
}

.single-blog-item-two .blog-content {
  position: absolute;
  bottom: 0;
  padding: 25px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-two .blog-content span {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  padding-left: 18px;
  display: inline-block;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.single-blog-item-two .blog-content span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.single-blog-item-two .blog-content h3 {
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0 14px 0;
  font-weight: 500;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
  position: relative;
  line-height: 30px;
}

.single-blog-item-two .blog-content p {
  color: #ffffff;
  margin: 0 0 0 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}

.single-blog-item-two .blog-content .blog-btn {
  margin-top: 18px;
}

.single-blog-item-two .blog-content .blog-btn .blog-btn-one {
  display: inline-block;
  text-align: center;
  z-index: 1;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid #ffffff;
  position: relative;
  z-index: 1;
}

.single-blog-item-two .blog-content .blog-btn .blog-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-blog-item-two .blog-content .blog-btn .blog-btn-one:hover {
  background-color: #209e2e;
  color: #ffffff !important;
}

.single-blog-item-two::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
}

.single-blog-item-two:hover::before {
  height: 100%;
}

.single-blog-item-two:hover span {
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-two:hover h3 {
  -webkit-transform: translateY(-72px);
  transform: translateY(-72px);
}

.single-blog-item-two:hover p {
  opacity: 1;
  -webkit-transform: translateY(-95px);
  transform: translateY(-95px);
}

.single-blog-item-three {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 30px;
}

.single-blog-item-three .blog-image {
  position: relative;
}

.single-blog-item-three .blog-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.5;
}

.single-blog-item-three .blog-content {
  position: absolute;
  bottom: 0;
  padding: 25px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-three .blog-content span {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  padding-left: 18px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  display: inline-block;
  z-index: 1;
}

.single-blog-item-three .blog-content span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  left: 0;
  top: 4px;
  border-radius: 30px;
}

.single-blog-item-three .blog-content h3 {
  font-size: 20px;
  color: #ffffff;
  margin: 10px 0 14px 0;
  font-weight: 500;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 1.4;
}

.single-blog-item-three .blog-content p {
  color: #ffffff;
  margin: 0 0 0 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: absolute;
  opacity: 0;
  bottom: 0;
}

.single-blog-item-three .blog-content .blog-btn {
  margin-top: 20px;
}

.single-blog-item-three .blog-content .blog-btn .blog-btn-one {
  display: inline-block;
  text-align: center;
  z-index: 1;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid #ffffff;
}

.single-blog-item-three .blog-content .blog-btn .blog-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-blog-item-three .blog-content .blog-btn .blog-btn-one:hover {
  background-color: #209e2e;
  color: #ffffff !important;
  border: 1px solid #209e2e;
}

.single-blog-item-three:hover span {
  -webkit-transform: translateY(-90px);
  transform: translateY(-90px);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-blog-item-three:hover h3 {
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.single-blog-item-three:hover p {
  opacity: 1;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
}

/*================================================
Partner Section CSS
=================================================*/
.partner-section {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #eef7ef;
}

.partner-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.partner-list .partner-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.partner-list .partner-item a {
  display: block;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
}

.partner-list .partner-item:nth-child(1) {
  border-bottom: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(2) {
  border-bottom: 1px dashed #209e2e;
  border-right: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(3) {
  border-bottom: 1px dashed #209e2e;
  border-right: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(4) {
  border-bottom: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(5) {
  border-bottom: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(6) {
  margin-left: 20%;
  border-right: 1px dashed #209e2e;
}

.partner-list .partner-item:nth-child(7) {
  border-right: 1px dashed #209e2e;
}

/*================================================
Farm Area CSS
=================================================*/
.farm-item {
  position: relative;
  background-color: #d2ecd4;
  padding: 40px 40px 225px;
}

.farm-item .farm-content span {
  font-size: 14px;
  color: #209e2e;
}

.farm-item .farm-content h3 {
  font-size: 25px;
  margin: 5px 0 14px 0;
  color: #5a5959;
}

.farm-item .farm-content .farm-btn .farm-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #eafef1;
  color: #209e2e;
  border: 1px solid #209e2e;
  border-radius: 30px;
}

.farm-item .farm-content .farm-btn .farm-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.farm-item .farm-image {
  position: absolute;
  bottom: 0;
  right: -15px;
}

.farm-item.bg-fdedce {
  background-color: #fdedce;
}

.farm-item.bg-fdedce .farm-btn .farm-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #ffdd9b;
  color: #5a5959;
  border: 1px solid #5a5959;
  border-radius: 30px;
}

.farm-item.bg-fdedce .farm-btn .farm-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  background-color: #fefaf0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.partner-area.bg-white {
  background-color: #ffffff;
}

.partner-area.bg-white .partner-slider::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background-color: #ffffff;
  height: 100%;
  width: 1px;
  z-index: 1;
}

.partner-slider::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background-color: #fefaf0;
  height: 100%;
  width: 1px;
  z-index: 1;
}

.partner-slider .partner-item {
  text-align: center;
}

.partner-slider .partner-item img {
  display: inline-block;
  width: 120px;
  margin-bottom: 30px;
}

.partner-slider .partner-item a {
  display: block;
  text-align: center;
  position: relative;
  border-bottom: 1px dashed #209e2e;
}

.partner-slider .owl-item {
  border-right: 1px dashed #209e2e;
}

.partner-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.partner-slider.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  left: -45px;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
  z-index: 1;
}

.partner-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: -45px;
}

.partner-slider.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #ffffff !important;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.partner-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.partner-shape {
  position: absolute;
  top: 20%;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  -webkit-animation: moveBounce 5s linear infinite;
  animation: moveBounce 5s linear infinite;
}

.partner-shape-img1 {
  position: absolute;
  right: 0;
  top: 20%;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  z-index: -1;
  -webkit-animation: moveBounce 5s linear infinite;
  animation: moveBounce 5s linear infinite;
}

/*================================================
Cow Section CSS
=================================================*/
.cow-section {
  padding-bottom: 50px;
}

.cow-btn {
  margin-top: 50px;
}

.cow-btn .cow-btn-one {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
  border-radius: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-right: 20px;
}

.cow-btn .cow-btn-one:hover {
  background-color: #ffffff;
  color: #209e2e;
}

.cow-btn .cow-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.cow-btn .cow-btn-two {
  display: inline-block;
  border: none;
  padding: 12px 30px;
  background-color: #ffdd9b;
  color: #5c5b5a;
  border: 1px solid #5a5959;
  border-radius: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cow-btn .cow-btn-two:hover {
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
}

.cow-btn .cow-btn-two i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.cow-image {
  text-align: center;
}

/*================================================
Faq Section CSS
=================================================*/
.faq-section {
  background-image: url(../../assets/img/faq-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.faq-section::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #f6fbf7;
  opacity: 0.5;
  z-index: -1;
}

.faq-content span {
  font-size: 14px;
  color: #209e2e;
  font-weight: 400;
  text-transform: uppercase;
}

.faq-content h3 {
  font-size: 38px;
  margin: 14px 0 0 0;
}

.faq-content .faq-btn {
  margin-top: 30px;
}

.faq-accordion .accordion {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.faq-accordion .accordion .accordion-item {
  margin-bottom: 15px;
  border: none;
  background-color: transparent;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-title {
  font-size: 20px;
  color: #696d8b;
  font-weight: 600;
}

.faq-accordion .accordion .accordion-item .accordion-title i {
  color: #209e2e;
  font-size: 16px;
  line-height: normal;
  margin-right: 5px;
}

.faq-accordion .accordion .accordion-item .accordion-content {
  padding-top: 10px;
  display: none;
}

.faq-accordion .accordion .accordion-item .accordion-content.show {
  display: block;
}

/*================================================
Cultivation Area CSS
=================================================*/
.cultivation-area {
  background-image: url(../../assets/img/cultivation-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.cultivation-title {
  text-align: center;
  margin-bottom: 50px;
}

.cultivation-title span {
  font-size: 15px;
  color: #ffffff;
  text-transform: uppercase;
}

.cultivation-title h3 {
  font-size: 38px;
  color: #ffffff;
}

.cultivation-item {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item .cultivation-image {
  position: relative;
  border-radius: 50%;
}

.cultivation-item .cultivation-image img {
  border-radius: 50%;
  width: 100%;
}

.cultivation-item .cultivation-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: #209e2e;
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  visibility: hidden;
}

.cultivation-item .cultivation-image .details-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 75%;
  -webkit-transform: translateY(-75%);
  transform: translateY(-75%);
  z-index: 2;
  color: #209e2e;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-color: #eafef1;
  margin: 0 auto;
  line-height: 42px;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
}

.cultivation-item .cultivation-image .details-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
  border-color: #209e2e;
}

.cultivation-item .cultivation-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 80px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item .cultivation-content h3 {
  margin-bottom: 0;
  font-size: 25px;
  color: #209e2e;
  font-weight: 500;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item .cultivation-content span {
  display: block;
  font-size: 16px;
  color: #209e2e;
  font-weight: 400;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  margin-bottom: 5px;
}

.cultivation-item .cultivation-content .icon i {
  font-size: 50px;
  line-height: normal;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cultivation-item:hover .cultivation-image::before {
  opacity: 0.6;
  visibility: visible;
}

.cultivation-item:hover .cultivation-image .details-btn {
  opacity: 1;
  visibility: visible;
}

.cultivation-item:hover .cultivation-content {
  bottom: 130px;
}

.cultivation-item:hover h3 {
  color: #ffffff;
}

.cultivation-item:hover span {
  color: #ffffff;
}

.cultivation-item:hover .icon i {
  color: #ffffff;
}

/*================================================
Client Section CSS
=================================================*/
.client-section {
  background-image: url(../../assets/img/client-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.client-title {
  text-align: center;
  margin-bottom: 50px;
}

.client-title span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
  font-weight: 400;
}

.client-title h3 {
  font-size: 38px;
  max-width: 430px;
  margin: 10px auto 0;
}

.client-slider {
  position: relative;
}

.client-slider .client-item {
  text-align: center;
}

.client-slider .client-item .client-info {
  position: relative;
}

.client-slider .client-item .client-info img {
  display: inline-block;
  width: 100px;
  border-radius: 50px;
}

.client-slider .client-item .client-info .icon {
  font-size: 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #e8f5ea;
  text-align: center;
  border-radius: 50px;
  color: #209e2e;
}

.client-slider .client-item .client-info .star-list {
  padding-left: 0;
  margin-bottom: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  display: inline-block;
  padding: 5px;
  list-style-type: none;
  border-radius: 5px;
  border-radius: 4px;
  position: relative;
  left: -28px;
  top: 20px;
}

.client-slider .client-item .client-info .star-list li {
  display: inline-block;
  margin-right: 5px;
}

.client-slider .client-item .client-info .star-list li:last-child {
  margin-right: 0;
}

.client-slider .client-item .client-info .star-list li i {
  color: #f4a708;
  font-size: 12px;
}

.client-slider .client-item .client-content {
  margin-top: 20px;
}

.client-slider .client-item .client-content p {
  max-width: 690px;
  margin: 0 auto 20px;
  font-style: italic;
  font-size: 18px;
}

.client-slider .client-item .client-content h3 {
  font-size: 20px;
  color: #616161;
  margin: 0 0 0 0;
  display: inline-block;
}

.client-slider .client-item .client-content span {
  display: inline-block;
  font-size: 15px;
  color: #209e2e;
}

.client-slider.owl-theme .owl-nav {
  opacity: 0;
  margin-top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.client-slider.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  left: 0;
  top: 50%;
  height: 40px;
  width: 40px;
  line-height: 38px;
  background-color: #209e2e !important;
  border: 1px solid #209e2e !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  color: #ffffff !important;
  font-size: 16px;
  border-radius: 50px;
}

.client-slider.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: 0;
}

.client-slider.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #209e2e !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.client-slider.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

/*================================================
Triple Section CSS
=================================================*/
.triple-section {
  padding-top: 60px;
}

.triple-item {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 5px;
  z-index: 1;
}

.triple-item .triple-image img {
  border-radius: 5px;
  width: 100%;
}

.triple-item .triple-content {
  position: absolute;
  bottom: 0;
  padding: 35px;
  width: 100%;
}

.triple-item .triple-content span {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  z-index: 1;
}

.triple-item .triple-content h3 {
  font-size: 35px;
  color: #ffffff;
  margin: 12px 0 0 0;
  position: relative;
  z-index: 1;
}

.triple-item .triple-content .triple-btn {
  position: relative;
  z-index: 1;
  margin-top: 20px;
}

.triple-item .triple-content .triple-btn .triple-btn-one {
  display: inline-block;
  color: #209e2e !important;
  text-transform: capitalize;
  border-radius: 50px;
  font-weight: 400;
  padding: 12px 30px;
  background-color: #eafef1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border: 1px solid #eafef1;
}

.triple-item .triple-content .triple-btn .triple-btn-one i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.triple-item .triple-shape {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: relative;
}

.triple-item .triple-shape img {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.triple-item .triple-shape img:last-child {
  opacity: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.triple-item:hover .triple-shape img:last-child {
  opacity: 1;
}

/*================================================
Counter Section CSS
=================================================*/
.counter-section {
  background-color: #fdf8ef;
  position: relative;
  z-index: 1;
}

.col-lg-3:last-child .single-counter {
  border-right: none;
}

.single-counter {
  text-align: center;
  position: relative;
  border-right: 1px dashed #209e2e;
  margin-bottom: 30px;
}

.single-counter h3 {
  position: relative;
  color: #209e2e;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 50px;
  font-weight: 300;
}

.single-counter h3 .sign-icon {
  display: inline-block;
  font-size: 50px;
  position: relative;
  top: 4px;
  right: 10px;
}

.single-counter p {
  line-height: initial;
  color: #209e2e;
  margin: 0 0 0 0;
  font-weight: 400;
  font-size: 16px;
}

/*================================================
Brief Section CSS
=================================================*/
.brief-section {
  background-image: url(../../assets/img/brief-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.brief-btn {
  margin-top: 30px;
}

.brief-image {
  text-align: center;
}

/*================================================
Sector Section CSS
=================================================*/
.sector-section {
  background-color: #fffbf3;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.sector-content {
  max-width: 590px;
  margin-left: auto;
}

.sector-content span {
  font-size: 15px;
  color: #209e2e;
  font-weight: 400;
}

.sector-content h3 {
  font-size: 38px;
  margin: 10px 0 16px 0;
}

.sector-content p {
  color: #7a7e9a;
  margin: 0 0 0 0;
}

.sector-content .sector-btn {
  margin-top: 25px;
}

.cloud-img1 {
  position: absolute;
  left: 10%;
  bottom: 48%;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.cloud-img2 {
  position: absolute;
  left: 20%;
  top: 10%;
  z-index: -1;
  -webkit-animation: moveScale 3s linear infinite;
  animation: moveScale 3s linear infinite;
}

.cloud-img3 {
  position: absolute;
  left: 65%;
  right: auto;
  top: 50px;
  z-index: -1;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

.sun-image {
  position: absolute;
  left: 50%;
  top: 10%;
  z-index: -1;
}

.sun-image img {
  -webkit-animation-name: rotateMe;
  animation-name: rotateMe;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*================================================
Faq Area CSS
=================================================*/
.faq-area {
  border-bottom: 1px solid #f1f1f1;
}

.faq-accordion-content .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion-content .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
  border: none;
}

.faq-accordion-content .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion-content .accordion .accordion-title {
  padding: 18px 20px 18px 55px;
  color: #333333;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.faq-accordion-content .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #209e2e;
  color: #ffffff;
}

.faq-accordion-content .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 13px;
}

.faq-accordion-content .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}

.faq-accordion-content .accordion .accordion-title.active i::before {
  content: "\f068";
}

.faq-accordion-content .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px;
  font-size: 15px;
}

.faq-accordion-content .accordion .accordion-content.show {
  display: block;
}

.faq-contact {
  margin-top: 70px;
}

.faq-contact-form {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
}

.faq-contact-form form .form-group {
  margin-bottom: 15px;
}

.faq-contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 60px;
  font-size: 15px;
}

.faq-contact-form form textarea.form-control {
  height: auto;
  padding-top: 15px;
  line-height: initial;
}

.faq-contact-form form .btn {
  margin-top: 8px;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}

.faq-contact-form form .help-block ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 0;
}

.faq-contact-form form .help-block ul li {
  color: red;
  font-weight: 300;
  text-align: left;
}

.faq-contact-form form #msgSubmit {
  margin-bottom: 0;
  text-align: center !important;
}

.faq-contact-form form #msgSubmit.text-danger,
.faq-contact-form form #msgSubmit.text-success {
  margin-top: 8px;
  font-size: 22px;
  font-weight: 300;
}

.faq-contact-form .send-btn {
  margin-top: 10px;
}

.faq-contact-form .send-btn .optional-btn {
  margin-left: 0;
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-title {
  text-align: center;
  margin-bottom: 50px;
}

.feedback-title span {
  font-size: 15px;
  color: #209e2e;
  text-transform: uppercase;
  font-weight: 400;
}

.feedback-title h3 {
  font-size: 38px;
  max-width: 430px;
  margin: 8px auto 0;
}

.feedback-slider {
  position: relative;
}

.feedback-slider .feedback-item {
  text-align: center;
  background-color: #e9f5ea;
  padding: 30px;
  margin-top: 36px;
  border: 1px dashed #209e2e;
  position: relative;
}

.feedback-slider .feedback-item .feedback-info {
  position: relative;
}

.feedback-slider .feedback-item .feedback-info img {
  display: inline-block;
  width: 100px;
  border-radius: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.feedback-slider .feedback-item .feedback-info .star-list {
  padding-left: 0;
  margin-bottom: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  display: inline-block;
  padding: 5px;
  list-style-type: none;
  border-radius: 5px;
  border-radius: 4px;
  position: relative;
  left: 0;
  top: 20px;
}

.feedback-slider .feedback-item .feedback-info .star-list li {
  display: inline-block;
  margin-right: 5px;
}

.feedback-slider .feedback-item .feedback-info .star-list li:last-child {
  margin-right: 0;
}

.feedback-slider .feedback-item .feedback-info .star-list li i {
  color: #f4a708;
  font-size: 12px;
}

.feedback-slider .feedback-item .feedback-content {
  margin-top: 50px;
}

.feedback-slider .feedback-item .feedback-content h3 {
  font-size: 20px;
  margin: 0 0 0 0;
}

.feedback-slider .feedback-item .feedback-content span {
  color: #209e2e;
  font-size: 15px;
  font-weight: 400;
}

.feedback-slider .feedback-item .feedback-content p {
  color: #686d8a;
  margin: 15px 0 0 0;
}

.feedback-slider .owl-item.active.center .feedback-item {
  background-color: #ffffff !important;
  border: 1px dashed #209e2e !important;
}

.feedback-slider.owl-theme .owl-nav.disabled+.owl-dots {
  line-height: 0.01;
  margin-top: 45px;
  margin-bottom: 0;
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
}

.feedback-slider.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
  border: 1px solid #209e2e;
  position: relative;
}

.feedback-slider.owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #209e2e;
  border-radius: 50%;
  margin: 4px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.feedback-slider.owl-theme .owl-dots .owl-dot:hover span::before,
.feedback-slider.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  position: relative;
  background-image: url(../../assets/img/subscribe-bg.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.subscribe-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #f6fbf7;
  left: 0;
  top: 0;
  opacity: 0.5;
  z-index: -1;
}

.subscribe-content {
  text-align: center;
  margin-bottom: 50px;
}

.subscribe-content p {
  color: #7a7e9a;
  max-width: 600px;
  margin: auto;
}

.subscribe-content h2 {
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
  font-size: 38px;
}

.newsletter-form {
  max-width: 780px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.newsletter-form .input-newsletter {
  display: inline-block;
  width: 70%;
  background-color: #ffffff;
  border: none;
  height: 70px;
  padding-left: 25px;
  border-radius: 5px;
  outline: 0;
  color: #333333;
  border-radius: 50px;
  border: 1px dashed #209e2e;
}

.newsletter-form .input-newsletter::-webkit-input-placeholder {
  color: #7a7e9a;
}

.newsletter-form .input-newsletter:-ms-input-placeholder {
  color: #7a7e9a;
}

.newsletter-form .input-newsletter::-ms-input-placeholder {
  color: #7a7e9a;
}

.newsletter-form .input-newsletter::placeholder {
  color: #7a7e9a;
}

.newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #209e2e;
  color: #ffffff;
  border: none;
  height: 70px;
  padding: 0 45px;
  border-radius: 50px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 50px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}

.newsletter-form button:hover {
  background-color: #333333;
  color: #ffffff;
}

.newsletter-form button i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.newsletter-form #validator-newsletter {
  color: red;
  margin-top: 10px;
}

/*================================================
Country Area CSS
=================================================*/
.country-area {
  overflow: hidden;
}

.country-item .country-content {
  margin-bottom: 25px;
}

.country-item .country-content span {
  font-size: 15px;
  color: #209e2e;
  font-weight: 400;
  text-transform: uppercase;
}

.country-item .country-content h3 {
  font-size: 38px;
  margin: 10px 0 16px 0;
}

.country-item .country-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.country-item .country-list li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
  margin-top: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}

.country-item .country-list i {
  font-size: 16px;
  margin-right: 4px;
}

.country-item h3 {
  font-size: 25px;
  margin: 0 0 0 0;
}

.country-item .country-btn {
  margin-top: 30px;
}

.map-img {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  z-index: 1;
  text-align: center;
}

.map-img .location {
  position: absolute;
  left: 14%;
  top: 40%;
  width: auto;
  height: auto;
}

.map-img .location a::before {
  content: "";
  position: absolute;
  left: -4px;
  bottom: -4px;
  border: 4px solid #209e2e;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  background: #ffffff;
}

.map-img .location a .location-info {
  position: absolute;
  left: -60px;
  bottom: 30px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  width: 160px;
  background: #ffffff;
  text-align: center;
  padding: 5px 15px;
  border-radius: 3px;
  z-index: 1;
  -webkit-box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.06);
}

.map-img .location a .location-info::before {
  content: "";
  position: absolute;
  left: 50px;
  bottom: -10px;
  width: 25px;
  height: 25px;
  background: #ffffff;
  z-index: -1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
}

.map-img .location a .location-info h5 {
  font-size: 16px;
  margin: 5px 0 5px 0;
  color: #209e2e;
}

.map-img .location.location2 {
  right: 50%;
  left: auto;
  top: 25%;
}

.map-img .location.location3 {
  right: 96%;
  left: auto;
  top: 76%;
}

.map-img .location.location4 {
  right: 50%;
  left: auto;
  top: 60%;
}

.map-img .location.location5 {
  right: 56%;
  left: auto;
  top: 90%;
}

.map-img .location.location6 {
  right: 20%;
  left: auto;
  top: 90%;
}

.map-img .location a:hover .location-info,
.map-img .location a .map-img .location a:focus .location-info,
.map-img .location a .map-img .location a.active .location-info {
  bottom: 40px;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background-image: url(../../assets/img/page-title-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.page-title-area::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: -1;
}

.page-title-content {
  text-align: center;
  margin-top: 100px;
}

.page-title-content h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 42px;
  font-weight: 700;
}

.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 12px;
  margin-bottom: 0;
}

.page-title-content ul li {
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.page-title-content ul li a {
  display: inline-block;
  color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.page-title-content ul li a:hover {
  color: #209e2e;
}

.page-title-content ul li::before {
  content: "";
  position: absolute;
  right: -15px;
  top: 11px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #209e2e;
}

.page-title-content ul li:last-child::before {
  display: none;
}

.item-bg1 {
  background-image: url(../../assets/img/page-title-bg-2.jpg);
}

.item-bg2 {
  background-image: url(../../assets/img/page-title-bg-3.jpg);
}

/*================================================
Team Section CSS
=================================================*/
.single-team-item {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  text-align: center;
}

.single-team-item .team-image {
  position: relative;
}

.single-team-item .team-image img {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  width: 100%;
}

.single-team-item .team-image .team-social {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  z-index: 2;
  text-decoration: none;
  opacity: 0;
  margin-top: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-team-item .team-image .team-social a {
  display: inline-block;
  color: #209e2e;
  font-size: 18px;
  margin: 0 6px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #209e2e;
  color: #ffffff;
  border-radius: 35px;
  font-size: 22px;
}

.single-team-item .team-image .team-social a:hover {
  background-color: #ffffff;
  color: #209e2e;
}

.single-team-item .team-content {
  padding: 25px;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-team-item .team-content h3 {
  margin-bottom: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-size: 24px;
  font-weight: 700;
}

.single-team-item .team-content span {
  display: block;
  margin-top: 8px;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-weight: 500;
}

.single-team-item:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.single-team-item:hover .team-image .team-social {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
}

.single-team-item:hover .team-content {
  background-color: #209e2e;
}

.single-team-item:hover .team-content h3 {
  color: #ffffff;
}

.single-team-item:hover .team-content span {
  color: #ffffff;
}

/*================================================
Gallery Area CSS
=================================================*/
.gallery-area .container-fluid {
  max-width: 1920px;
}

.single-gallery-box {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.single-gallery-box::before {
  width: 100%;
  content: "";
  position: absolute;
  height: 100%;
  background-color: #209e2e;
  left: 0;
  top: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 0;
  visibility: hidden;
}

.single-gallery-box .gallery-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #ffffff;
  color: #333333;
  font-size: 18px;
  margin-top: 15px;
  opacity: 0;
  visibility: hidden;
  border-radius: 50px;
}

.single-gallery-box .gallery-btn:hover {
  color: #ffffff;
  background-color: #333333;
}

.single-gallery-box:hover::before {
  opacity: 0.85;
  visibility: visible;
}

.single-gallery-box:hover .gallery-btn {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

/*================================================
Login In CSS
=================================================*/
.login-form {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}

.login-form .login-title {
  text-align: center;
  margin-bottom: 30px;
}

.login-form .login-title h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #343846;
}

.login-form .login-title p {
  margin-bottom: 30px;
}

.login-form form .form-group {
  margin-bottom: 15px;
}

.login-form form .form-control {
  height: 60px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #ffffff;
  padding-left: 20px;
  background: transparent;
}

.login-form form .form-control:focus {
  border-color: #209e2e;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-form form .form-control::-webkit-input-placeholder {
  color: #666666;
}

.login-form form .form-control:-ms-input-placeholder {
  color: #666666;
}

.login-form form .form-control::-ms-input-placeholder {
  color: #666666;
}

.login-form form .form-control::placeholder {
  color: #666666;
}

.login-form form .form-check {
  margin-top: 18px;
  position: relative;
  z-index: 1;
}

.login-form form .form-check .form-check-label {
  color: #666666;
}

.login-form form .send-btn {
  margin-top: 15px;
}

.login-form form span {
  display: block;
  color: #777777;
}

.login-form form span a {
  color: #209e2e;
}

.login-form .forgot-password {
  margin-bottom: 0;
  margin-top: -25px;
  text-align: right;
}

.login-form .forgot-password a {
  color: #777777;
}

.login-form .forgot-password a:hover {
  color: #209e2e;
}

/*================================================
Sign Up CSS
=================================================*/
.signup-form {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}

.signup-form h3 {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
}

.signup-form form .form-group {
  margin-bottom: 15px;
}

.signup-form form .form-control {
  height: 60px;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  color: #ffffff;
  padding-left: 20px;
  background: transparent;
}

.signup-form form .form-control:focus {
  border-color: #209e2e;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.signup-form form .form-control::-webkit-input-placeholder {
  color: #666666;
}

.signup-form form .form-control:-ms-input-placeholder {
  color: #666666;
}

.signup-form form .form-control::-ms-input-placeholder {
  color: #666666;
}

.signup-form form .form-control::placeholder {
  color: #666666;
}

.signup-form form .form-check {
  margin-top: 18px;
}

.signup-form form .form-check .form-check-label {
  color: #666666;
}

.signup-form form .send-btn {
  margin-top: 15px;
}

.signup-form form span {
  display: block;
  color: #666666;
}

.signup-form form span a {
  color: #209e2e;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}

.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}

.error-content h3 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 45px;
  margin-bottom: 15px;
}

.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
}

/*================================================
Contact Section CSS
=================================================*/
.contact-area .contact-form {
  -webkit-box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.contact-area .contact-form form {
  text-align: center;
}

.contact-area .contact-form form .form-group {
  margin-bottom: 15px;
}

.contact-area .contact-form form .form-control {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 57px;
  color: #209e2e;
  border: 1px solid #ffffff;
}

.contact-area .contact-form form .form-control:focus {
  border: 1px solid #209e2e;
}

.contact-area .contact-form form .form-group {
  text-align: left;
}

.contact-area .contact-form form textarea.form-control {
  height: 120px;
  padding-top: 15px;
}

.contact-area .contact-form .default-btn {
  cursor: pointer;
  margin-top: 10px;
}

.contact-area .contact-form .list-unstyled {
  padding-left: 0;
  list-style: none;
  color: red;
}

.contact-area .contact-form .text-danger {
  color: #dc3545 !important;
  font-size: 20px;
  font-weight: 500 !important;
  margin: 16px 0 0 0;
}

.contact-box {
  padding-top: 100px;
  padding-bottom: 35px;
}

.single-contact-box {
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 30px;
}

.single-contact-box i {
  line-height: 50px;
  width: 50px;
  height: 50px;
  border: 1px dashed #209e2e;
  border-radius: 50%;
  font-size: 20px;
  display: inline-block;
  color: #209e2e;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  margin-bottom: 20px;
}

.single-contact-box:hover i {
  background-color: #209e2e;
  color: #ffffff;
}

.single-contact-box .content-title h3 {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 400;
}

.single-contact-box .content-title a {
  display: block;
  font-size: 16px;
  color: #747171;
  margin-bottom: 5px;
}

.single-contact-box .content-title a:hover {
  color: #209e2e;
}

/*================================================
Services Details CSS
=================================================*/
.services-details-overview {
  margin-bottom: 60px;
}

.services-details-overview:last-child {
  margin-bottom: 0;
}

.services-details-overview .services-details-desc h3 {
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: 600;
}

.services-details-overview .services-details-desc .features-text {
  margin-top: 25px;
}

.services-details-overview .services-details-desc .features-text h4 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.services-details-overview .services-details-desc .services-details-accordion {
  margin-top: 25px;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 10px;
  border: none;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-item a {
  text-decoration: none;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title {
  padding: 15px 20px 15px 51px;
  color: #000000;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: inline-block;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #209e2e;
  color: #ffffff;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 13px;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active {
  border-bottom-color: #eeeeee;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}

.services-details-overview .services-details-desc .services-details-accordion .accordion .accordion-content.show {
  display: block;
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area .widget {
  margin-top: 35px;
}

.widget-area .widget:first-child {
  margin-top: 0;
}

.widget-area .widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
  text-transform: capitalize;
  position: relative;
  font-size: 22px;
}

.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  background: #209e2e;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.widget-area .widget_search {
  -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  background-color: #ffffff;
  padding: 15px;
}

.widget-area .widget_search form {
  position: relative;
}

.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}

.widget-area .widget_search form .screen-reader-text {
  display: none;
}

.widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.widget-area .widget_search form .search-field:focus {
  border-color: #209e2e;
}

.widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: #ffffff;
  background-color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.widget-area .widget_search form button:hover {
  background-color: #333333;
  color: #ffffff;
}

.widget-area .widget_abaris_posts_thumb {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_abaris_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}

.widget-area .widget_abaris_posts_thumb .item:last-child {
  margin-bottom: 0;
}

.widget-area .widget_abaris_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../../assets/img/blog-details/1.jpg);
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../../assets/img/blog-details/2.jpg);
}

.widget-area .widget_abaris_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../../assets/img/blog-details/3.jpg);
}

.widget-area .widget_abaris_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}

.widget-area .widget_abaris_posts_thumb .item .info time {
  display: block;
  color: #7a7e9a;
  text-transform: capitalize;
  margin-top: -2px;
  margin-bottom: 3px;
  font-size: 14px;
}

.widget-area .widget_abaris_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 18px;
}

.widget-area .widget_abaris_posts_thumb .item .info .title a {
  display: inline-block;
}

.widget-area .widget_recent_entries ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #333333;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15.5px;
}

.widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_recent_entries ul li::before {
  background: #209e2e;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
}

.widget-area .widget_recent_entries ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_recent_entries ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #7a7e9a;
  margin-top: 4px;
}

.widget-area .widget_recent_comments ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_recent_comments ul li {
  position: relative;
  margin-bottom: 12px;
  color: #7a7e9a;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15.5px;
}

.widget-area .widget_recent_comments ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_recent_comments ul li::before {
  background: #209e2e;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
}

.widget-area .widget_recent_comments ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_recent_comments ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_archive ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_archive ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  color: #333333;
  font-size: 15.5px;
  font-weight: 400;
}

.widget-area .widget_archive ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_archive ul li::before {
  background: #209e2e;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}

.widget-area .widget_archive ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_archive ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  color: #7a7e9a;
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 400;
}

.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
  background: #209e2e;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}

.widget-area .widget_categories ul li a {
  color: #333333;
  display: block;
}

.widget-area .widget_categories ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_categories ul li .post-count {
  float: right;
}

.widget-area .widget_meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_meta ul li {
  position: relative;
  margin-bottom: 12px;
  color: #333333;
  padding-left: 14px;
  font-size: 15.5px;
  font-weight: 400;
}

.widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_meta ul li::before {
  background: #209e2e;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 8px;
  position: absolute;
}

.widget-area .widget_meta ul li a {
  display: inline-block;
  color: #333333;
}

.widget-area .widget_meta ul li a:hover {
  color: #209e2e;
}

.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 12px;
}

.widget-area .tagcloud a {
  display: inline-block;
  color: #333333;
  font-weight: 400;
  font-size: 14.5px !important;
  padding: 6px 13px;
  border: 1px dashed #eeeeee;
  margin-top: 8px;
  margin-right: 4px;
}

.widget-area .tagcloud a:hover,
.widget-area .tagcloud a:focus {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.widget-area .widget_event_details ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_event_details ul li {
  border-bottom: 1px solid #eeeeee;
  color: #888f96;
  padding-bottom: 10px;
  padding-top: 10px;
}

.widget-area .widget_event_details ul li:first-child {
  padding-top: 0;
}

.widget-area .widget_event_details ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget-area .widget_event_details ul li span {
  display: inline-block;
  color: #333333;
  font-weight: 400;
}

.widget-area .widget_event_details ul li a {
  display: inline-block;
  color: #888f96;
}

.widget-area .widget_event_details ul li a:hover {
  color: #209e2e;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-content {
  margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta {
  margin-bottom: -8px;
}

.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #333333;
  margin-right: 21px;
}

.blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: #333333;
  font-weight: 500;
}

.blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: #7a7e9a;
}

.blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #209e2e;
}

.blog-details-desc .article-content .entry-meta ul li i {
  color: #209e2e;
  margin-right: 2px;
}

.blog-details-desc .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 12px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #209e2e;
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}

.blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}

.blog-details-desc .article-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 20px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  -ms-flex: 0 0 33.3333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}

.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}

.blog-details-desc .article-content .features-list li {
  margin-bottom: 16px;
  position: relative;
  padding-left: 34px;
  color: #7a7e9a;
}

.blog-details-desc .article-content .features-list li i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 40px;
  background-color: #faf5f5;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  display: inline-block;
  font-size: 11px;
  position: absolute;
  left: 0;
  top: -2px;
}

.blog-details-desc .article-content .features-list li:hover i {
  background-color: #209e2e;
  color: #ffffff;
}

.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}

.blog-details-desc .article-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  color: #7a7e9a;
}

.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: #333333;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #7a7e9a;
}

.blog-details-desc .article-footer .article-tags a:hover {
  color: #209e2e;
}

.blog-details-desc .article-footer .article-share {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}

.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}

.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: #7a7e9a;
}

.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: #209e2e;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #eeeef0;
  text-align: center;
  font-size: 12px;
}

.blog-details-desc .article-footer .article-share .social li a:hover {
  color: #ffffff;
  background-color: #209e2e;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.blog-details-desc .post-navigation {
  margin-top: 30px;
}

blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}

blockquote p,
.blockquote p {
  color: #333333;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 24px !important;
}

blockquote cite,
.blockquote cite {
  display: none;
}

blockquote::before,
.blockquote::before {
  color: #efefef;
  content: "\f10e";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "Font Awesome 5 Free";
  font-size: 140px;
  font-weight: 900;
}

blockquote::after,
.blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #209e2e;
  margin-top: 20px;
  margin-bottom: 20px;
}

.post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}

.post-navigation .navigation-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.post-navigation .navigation-links .nav-previous {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.post-navigation .navigation-links .nav-previous a i {
  margin-right: 2px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.post-navigation .navigation-links .nav-previous a:hover i {
  margin-right: 0;
}

.post-navigation .navigation-links .nav-next {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}

.post-navigation .navigation-links .nav-next a i {
  margin-left: 2px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.post-navigation .navigation-links .nav-next a:hover i {
  margin-left: 0;
}

.post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 400;
}

.comments-area {
  padding: 25px;
  margin-top: 30px;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background-color: #ffffff;
}

.comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 24px;
  font-weight: 600;
}

.comments-area ol,
.comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  color: #333333;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.comments-area .comment-body .reply {
  margin-top: 15px;
}

.comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: #333333;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
}

.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.comments-area .comment-author {
  font-size: 17px;
  margin-bottom: 0.1em;
  position: relative;
  z-index: 2;
}

.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}

.comments-area .comment-author .fn {
  font-weight: 500;
}

.comments-area .comment-author .says {
  display: none;
}

.comments-area .comment-metadata {
  margin-bottom: 0.8em;
  color: #7a7e9a;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}

.comments-area .comment-metadata a {
  color: #7a7e9a;
}

.comments-area .comment-metadata a:hover {
  color: #209e2e;
}

.comments-area .comment-respond {
  margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  position: relative;
  font-size: 24px;
  font-weight: 600;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}

.comments-area .comment-respond .comment-form {
  overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
  margin-bottom: 0;
  margin-top: 10px;
}

.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}

.comments-area .comment-respond label {
  display: block;
  font-weight: 400;
  color: #333333;
  margin-bottom: 5px;
}

.comments-area .comment-respond input[type="date"],
.comments-area .comment-respond input[type="time"],
.comments-area .comment-respond input[type="datetime-local"],
.comments-area .comment-respond input[type="week"],
.comments-area .comment-respond input[type="month"],
.comments-area .comment-respond input[type="text"],
.comments-area .comment-respond input[type="email"],
.comments-area .comment-respond input[type="url"],
.comments-area .comment-respond input[type="password"],
.comments-area .comment-respond input[type="search"],
.comments-area .comment-respond input[type="tel"],
.comments-area .comment-respond input[type="number"],
.comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.comments-area .comment-respond input[type="date"]:focus,
.comments-area .comment-respond input[type="time"]:focus,
.comments-area .comment-respond input[type="datetime-local"]:focus,
.comments-area .comment-respond input[type="week"]:focus,
.comments-area .comment-respond input[type="month"]:focus,
.comments-area .comment-respond input[type="text"]:focus,
.comments-area .comment-respond input[type="email"]:focus,
.comments-area .comment-respond input[type="url"]:focus,
.comments-area .comment-respond input[type="password"]:focus,
.comments-area .comment-respond input[type="search"]:focus,
.comments-area .comment-respond input[type="tel"]:focus,
.comments-area .comment-respond input[type="number"]:focus,
.comments-area .comment-respond textarea:focus {
  border-color: #209e2e;
}

.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 4px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #7a7e9a;
  font-weight: normal;
  position: relative;
  top: -2px;
}

.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}

.comments-area .comment-respond .form-submit input {
  background: #209e2e;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: capitalize;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-weight: 400;
  font-size: 14px;
}

.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background-color: #333333;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 20px;
  text-align: center;
}

.pagination-area .page-numbers {
  width: 45px;
  height: 45px;
  margin: 0 3px;
  display: inline-block;
  background-color: #ffffff;
  line-height: 48px;
  color: #333333;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
  font-size: 18px;
  font-weight: 700;
}

.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover,
.pagination-area .page-numbers:focus {
  background: #209e2e;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
}

/*================================================
Product Details Area CSS
=================================================*/
.product-details-desc h3 {
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: 700;
}

.product-details-desc .price {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  column-gap: 10px;
}

.product-details-desc .allTaxes {
  font-size: 12px;
}

.product-details-desc .price .old-price {
  text-decoration: line-through;
  color: #828893;
}

.product-details-desc .product-review {
  margin-bottom: 15px;
}

.product-details-desc .product-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 14px;
}

.product-details-desc .product-review .rating i,
.product-details-desc .product-review .rating svg {
  color: #ffba0a;
}

.product-details-desc .product-review .rating-count {
  display: inline-block;
  color: #000000;
  border-bottom: 1px solid #000000;
  line-height: initial;
}

.product-details-desc .product-review .rating-count:hover {
  color: #209e2e;
  border-color: #209e2e;
  text-decoration: none;
}

.product-details-desc .product-add-to-cart {
  margin-top: 20px;
  display: flex;
}

.product-details-desc .product-add-to-cart .input-counter {
  max-width: 130px;
  min-width: 130px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.product-details-desc .product-add-to-cart .input-counter span {
  position: absolute;
  top: -4px;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 50px;
  height: 100%;
  line-height: 55px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.product-details-desc .product-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.product-details-desc .product-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.product-details-desc .product-add-to-cart .input-counter span:hover {
  color: #209e2e;
}

.product-details-desc .product-add-to-cart .input-counter input {
  height: 50px;
  color: #000000;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.product-details-desc .product-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .input-counter input:-ms-input-placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .input-counter input::-ms-input-placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .input-counter input::placeholder {
  color: #000000;
}

.product-details-desc .product-add-to-cart .default-btn {
  position: relative;
  border: none;
  padding: 12px 30px;
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.product-details-desc .product-add-to-cart .default-btn i {
  margin-right: 2px;
}

.product-details-desc .product-add-to-cart .default-btn:hover {
  background-color: #ffffff;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.product-details-desc .buy-checkbox-btn {
  margin-top: 20px;
}

.product-details-desc .buy-checkbox-btn input {
  display: none;
}

.product-details-desc .buy-checkbox-btn .cbx {
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.product-details-desc .buy-checkbox-btn .cbx span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  -webkit-transform: scale(1);
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.product-details-desc .buy-checkbox-btn .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #000000;
  display: block;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.product-details-desc .buy-checkbox-btn .cbx span:last-child {
  position: relative;
  top: 1px;
  padding-left: 4px;
  color: #666666;
}

.product-details-desc .buy-checkbox-btn .cbx:hover span:first-child {
  border-color: #209e2e;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child {
  background: #209e2e;
  border-color: #209e2e;
  -webkit-animation: wave 0.4s ease;
  animation: wave 0.4s ease;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.product-details-desc .buy-checkbox-btn .inp-cbx:checked+.cbx span:first-child:before {
  -webkit-transform: scale(3.5);
  transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.product-details-desc .buy-checkbox-btn .item:not(:first-child) {
  margin-top: 20px;
}

.product-details-desc .buy-checkbox-btn .btn-light {
  background-color: #f2f2f2;
  border: none;
  padding: 13px 25px 10px 25px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-weight: 600;
  display: block;
  width: 100%;
}

.product-details-desc .buy-checkbox-btn .btn-light:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.product-details-desc .custom-payment-options {
  margin-top: 20px;
}

.product-details-desc .custom-payment-options span {
  display: block;
  color: #666666;
  margin-bottom: 8px;
}

.product-details-desc .custom-payment-options .payment-methods {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.product-details-desc .custom-payment-options .payment-methods a img {
  width: 40px;
}

.products-details-tab {
  margin-top: 50px;
}

.products-details-tab .tabs {
  list-style-type: none;
  margin-bottom: -1px;
  padding-left: 0;
}

.products-details-tab .tabs li {
  display: inline-block;
  line-height: initial;
  margin-right: 5px;
}

.products-details-tab .tabs li a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: #333333;
  border: 1px dashed #eeeeee;
  text-decoration: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: 700;
  font-size: 15px;
}

.products-details-tab .tabs li a:hover,
.products-details-tab .tabs li a:focus {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.products-details-tab .tabs li.current a {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.products-details-tab .tabs li a.nav-link.active {
  color: #ffffff;
  background-color: #209e2e;
  border-color: #209e2e;
}

.products-details-tab .tabs li:last-child {
  margin-right: 0;
}

.products-details-tab .tab_content {
  border: 1px dashed #eeeeee;
  padding: 30px;
}

.products-details-tab .tab_content .tabs_item {
  display: none;
}

.products-details-tab .tab_content .tabs_item:first-child {
  display: block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content p {
  margin-bottom: 20px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content p:last-child {
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: left;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li {
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 10px 15px 7px;
  color: #666666;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li:last-child {
  border-bottom: 1px solid #eeeeee;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li span {
  display: inline-block;
  width: 30%;
  color: #333333;
  font-weight: 500;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 500;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title {
  position: relative;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating {
  display: inline-block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating .fas.fa-star {
  color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
  color: #ebebeb;
  font-size: 14px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 25px;
  background-color: #209e2e;
  border: 1px solid #209e2e;
  color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .default-btn:hover {
  background-color: #ffffff;
  color: #209e2e;
  text-decoration: none;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments {
  margin-top: 35px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
  margin-top: 30px;
  position: relative;
  padding-right: 200px;
  border-top: 1px dashed #eeeeee;
  padding-top: 30px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating .fas.fa-star {
  color: #ffba0a;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
  font-size: 14px;
  color: #ebebeb;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
  margin-bottom: 10px;
  font-size: 14px;
  display: block;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span strong {
  font-weight: 400;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item p {
  margin-bottom: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
  position: absolute;
  right: 0;
  color: #666666;
  top: 40px;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link:hover {
  color: #209e2e;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form {
  margin-top: 30px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form h3 {
  margin-bottom: 20px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form {
  max-width: 100%;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group {
  margin-bottom: 15px;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control {
  height: 55px;
  border-radius: 0;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group .form-control:focus {
  box-shadow: none;
  border-color: #ff8f00;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group textarea.form-control {
  padding-top: 15px;
  height: auto;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .default-btn {
  border: none;
  margin-top: 10px;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  padding: 14px 25px;
  background-color: #209e2e;
  color: #ffffff;
  border: 1px solid #209e2e;
  text-transform: uppercase;
}

.products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .default-btn:hover {
  background-color: #ffffff;
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.related-products {
  margin-top: 70px;
  text-align: center;
}

.related-products .products-title {
  margin-bottom: 10px;
  text-align: center;
}

.related-products .products-title span {
  font-size: 14px;
  color: #209e2e;
}

.related-products .products-title h2 {
  font-size: 30px;
  color: #333333;
  margin: 10px 0 0 0;
}

.related-products .single-product-box {
  margin-bottom: 0;
  margin-top: 30px;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead {
  text-align: center;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 25px 0 25px;
  text-transform: uppercase;
  border: none;
  font-weight: 500;
  font-size: 18px;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.cart-table table tbody tr td.product-thumbnail img {
  width: 60px;
  height: 60px;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-name a {
  color: #666666;
  font-weight: 500;
  display: inline-block;
  font-size: 16px;
}

.cart-table table tbody tr td.product-name a:hover {
  color: #333333;
}

.cart-table table tbody tr td.product-price span {
  font-weight: 500;
  font-size: 16px;
}

.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: #666666;
  float: right;
  position: relative;
  top: 1px;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.cart-table table tbody tr td.product-subtotal .remove i {
  font-size: 16px;
}

.cart-table table tbody tr td.product-subtotal .remove:hover {
  color: red;
}

.cart-table table tbody tr td.product-subtotal span {
  font-weight: 500;
  font-size: 16px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 48px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: #333333;
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: #333333;
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: #333333;
}

.cart-buttons {
  margin-top: 30px;
  text-align: center;
}

.cart-totals {
  background: #ffffff;
  padding: 40px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0;
  margin: auto;
  margin-top: 50px;
}

.cart-totals h3 {
  margin-bottom: 20px;
  font-size: 20px;
}

.cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 12px 15px;
  color: #323232;
  overflow: hidden;
  font-weight: 500;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  border-top: none;
}

.cart-totals ul li span {
  float: right;
  color: #666666;
  font-weight: normal;
}

.cart-totals .default-btn {
  width: 100%;
}

.cart-area .coupon-wrap {
  margin-top: 50px;
  background-color: #ffffff;
  padding: 40px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.cart-area .coupon-wrap h3 {
  padding-bottom: 25px;
  font-size: 20px;
}

.cart-area .coupon-wrap .shops-form {
  text-align: center;
}

.cart-area .coupon-wrap .shops-form .form-group {
  margin-bottom: 15px;
}

.cart-area .coupon-wrap .shops-form .form-group .form-control {
  height: 55px;
  color: #6e6e6e;
  border: 1px solid #e8e8e8;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  font-size: 15px;
  padding: 10px 20px;
  width: 100%;
}

.cart-area .coupon-wrap .shops-form .form-group .form-control:focus {
  border-color: #209e2e;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cart-area .coupon-wrap .shops-form .nice-select {
  height: 55px;
  line-height: 49px;
  border-radius: 0;
  width: 100%;
  margin-bottom: 15px;
}

.cart-area .coupon-wrap .shops-form .nice-select.open .list {
  width: 100%;
}

.cart-area .coupon-wrap .shops-form .default-btn {
  width: 100%;
}

.cart-wraps {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 40px;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 18px 20px 15px;
  margin-bottom: 65px;
  border-top: 3px solid #333333;
  position: relative;
}

.user-actions::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -35px;
  width: 100%;
  height: 1px;
  background: #eeeeee;
}

.user-actions i {
  color: #333333;
  margin-right: 2px;
}

.user-actions span {
  display: inline-block;
  font-weight: 400;
  color: #333333;
  font-size: 16px;
}

.user-actions span a {
  display: inline-block;
  color: #333333;
  text-decoration: none;
}

.user-actions span a:hover,
.user-actions span a:focus {
  color: #209e2e;
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 700;
}

.billing-details .title::before {
  content: "";
  position: absolute;
  background: #209e2e;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  display: block;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 400;
}

.billing-details .form-group label .required {
  color: #333333;
}

.billing-details .form-group .nice-select {
  float: unset;
  line-height: 53px;
  color: #666666;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
}

.billing-details .form-group .nice-select .list {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.billing-details .form-group .nice-select .list .option {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding-left: 20px;
  padding-right: 20px;
}

.billing-details .form-group .nice-select .list .option:hover {
  background-color: #209e2e !important;
  color: #ffffff;
}

.billing-details .form-group .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 600;
}

.billing-details .form-group .nice-select:after {
  right: 20px;
}

.billing-details .form-group .form-control {
  height: 50px;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-group .form-control:focus {
  border-color: #209e2e;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.billing-details .form-group textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.billing-details .form-check {
  margin-bottom: 20px;
}

.billing-details .form-check .form-check-label {
  color: #333333;
}

.billing-details .form-check label {
  position: relative;
  left: -3px;
  top: 1px;
  font-weight: 500;
}

.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 24px;
  font-weight: 700;
}

.order-details .title::before {
  content: "";
  position: absolute;
  background: #209e2e;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.order-details .order-table table {
  margin-bottom: 0;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #eaedff;
  text-transform: uppercase;
  padding-left: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 10px;
  font-weight: 600;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #666666;
  border-color: #eaedff;
  font-size: 15.5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 13px;
}

.order-details .order-table table tbody tr td.product-name a {
  color: #666666;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

.order-details .order-table table tbody tr td.product-name a:hover {
  color: #209e2e;
}

.order-details .order-table table tbody tr td.order-subtotal span,
.order-details .order-table table tbody tr td.order-shipping span,
.order-details .order-table table tbody tr td.total-price span {
  color: #666666;
  font-weight: 400;
}

.order-details .order-table table tbody tr td.shipping-price,
.order-details .order-table table tbody tr td.order-subtotal-price,
.order-details .order-table table tbody tr td.product-subtotal {
  font-weight: 400;
}

.order-details .payment-box {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  padding: 30px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  color: #333333;
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::before,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after,
.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #209e2e;
  position: absolute;
  top: 6px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked)+label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked+label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.order-details .payment-box .default-btn {
  padding: 14px 30px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  background-color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.order-details .payment-box .default-btn:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.order-details .payment-box .default-btn.order-btn {
  display: block;
  margin-top: 25px;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}

/*================================================
Privacy Section CSS
=================================================*/
.single-privacy h3 {
  font-size: 25px;
  margin: 0 0 12px 0;
}

/*================================================
Footer Section CSS
=================================================*/
.footer-section {
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer-section.footer-bg {
  background: #124a2f;
  padding-bottom: 30px;
}

.footer-section.footer-bg .single-footer-widget p {
  color: #f1f1f1;
}

.footer-section.footer-bg .single-footer-widget .footer-social a {
  background-color: #ffffff;
  color: #f5f5f5;
}

.footer-section.footer-bg .single-footer-widget .footer-social a:hover {
  background-color: #209e2e;
  color: #f5f5f5;
}

.footer-section.footer-bg .single-footer-widget .footer-heading h3 {
  color: #1a1a1a;
}

.footer-section.footer-bg .single-footer-widget .footer-quick-links li {
  color: #ffffff;
}

.footer-section.footer-bg .single-footer-widget .footer-quick-links li a {
  color: #f1f1f1;
}

.footer-section.footer-bg .single-footer-widget .footer-quick-links li a:hover {
  color: #209e2e;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact i {
  color: #b48017;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact h3 {
  color: #1a1a1a;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact span {
  color: #f1f1f1;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact span a {
  color: #f1f1f1;
}

.footer-section.footer-bg .single-footer-widget .footer-info-contact span a:hover {
  color: #209e2e;
}

.footer-section.footer-bg .single-footer-widget .footer-map {
  padding-left: 0;
  margin-bottom: 0;
}

.footer-section.footer-bg .single-footer-widget .footer-map li {
  list-style-type: none;
  font-size: 18px;
  font-weight: 500;
}

.footer-section.footer-bg .single-footer-widget .footer-map a {
  color: #209e2e;
}

.footer-section.footer-bg .copyright-area .copyright-area-content p {
  color: #ffffff;
}

.footer-section.footer-bg .copyright-area .copyright-area-content p a {
  color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.footer-section.footer-bg .copyright-area .copyright-area-content p a:hover {
  color: #209e2e;
}

.footer-section.footer-bg .copyright-area .copyright-area-content ul li {
  color: #ffffff;
}

.footer-section.footer-bg .copyright-area .copyright-area-content ul li a {
  color: #ffffff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.footer-section.footer-bg .copyright-area .copyright-area-content ul li a:hover {
  color: #209e2e;
}

.single-footer-widget {
  margin-bottom: 50px;
}

.single-footer-widget p {
  color: #7a7e9a;
  margin: 0 0 18px 0;
  line-height: 25px;
}

.single-footer-widget .footer-social {
  padding-left: 0;
  margin-bottom: 0;
}

.single-footer-widget .footer-social li {
  list-style-type: none;
  display: inline-block;
}

.single-footer-widget .footer-social a {
  display: inline-block;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2ecd5;
  color: #209e2e;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  margin-right: 5px;
}

.single-footer-widget .footer-social a:hover {
  background-color: #209e2e;
  color: #ffffff;
}

.single-footer-widget .footer-heading {
  margin-bottom: 30px;
}

.single-footer-widget .footer-heading h3 {
  font-size: 15px;
  color: #209e2e;
  margin: 0;
  text-transform: uppercase;
}

.single-footer-widget .footer-quick-links {
  padding-left: 0;
  margin-bottom: 0;
}

.single-footer-widget .footer-quick-links li {
  list-style-type: none;
  padding-bottom: 8px;
  color: #7a7e9a;
}

.single-footer-widget .footer-quick-links li:last-child {
  padding-bottom: 0;
}

.single-footer-widget .footer-quick-links li a {
  display: inline-block;
  color: #7a7e9a;
  font-size: 15px;
  font-weight: 400;
}

.single-footer-widget .footer-quick-links li a:hover {
  color: #209e2e;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-footer-widget .footer-info-contact {
  position: relative;
  margin-bottom: 16px;
}

.single-footer-widget .footer-info-contact:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-info-contact i {
  color: #209e2e;
  position: absolute;
  left: 0;
  top: -6px;
  font-size: 16px;
}

.single-footer-widget .footer-info-contact h3 {
  font-size: 14px;
  color: #209e2e;
  font-weight: 400;
  margin: 0 0 10px 0;
  padding-left: 25px;
  text-transform: uppercase;
}

.single-footer-widget .footer-info-contact span {
  font-size: 14px;
  color: #7a7e9a;
  font-weight: 400;
}

.single-footer-widget .footer-info-contact span a {
  font-size: 14px;
  color: #7a7e9a;
  font-weight: 400;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.single-footer-widget .footer-info-contact span a:hover {
  color: #209e2e;
}

.single-footer-widget .newsletter-form {
  max-width: 580px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.single-footer-widget .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  height: 50px;
  padding-left: 25px;
  border-radius: 0;
  outline: 0;
  color: #333333;
  font-size: 15px;
}

.single-footer-widget .newsletter-form .input-newsletter::-webkit-input-placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form .input-newsletter:-ms-input-placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form .input-newsletter::-ms-input-placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form .input-newsletter::placeholder {
  color: #7a7e9a;
}

.single-footer-widget .newsletter-form button {
  position: relative;
  background-color: #de392f;
  color: #ffffff;
  border: none;
  height: 50px;
  border-radius: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  line-height: 50px;
  text-transform: capitalize;
  width: 100%;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
}

.single-footer-widget .newsletter-form button:hover {
  background-color: #333333;
  color: #ffffff;
}

.single-footer-widget .newsletter-form button i {
  font-size: 14px;
  line-height: normal;
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.single-footer-widget .newsletter-form #validator-newsletter {
  font-size: 15px;
  color: red;
  margin-top: 10px;
}

.single-footer-widget .footer-info-contact .topIcon {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 14px;
}

.single-footer-widget .footer-info-contact .topIcon svg {
  color: #ffffff;
  font-size: 23px;
}

.single-footer-widget .footer-info-contact .topIcon h3 {
  margin: 0;
  padding-left: 0;
  font-weight: 600;
}

.copyright-area .copyright-area-content {
  padding-top: 30px;
  border-top: 1px solid #519b59;
}

.copyright-area .copyright-area-content p {
  color: #7a7e9a;
  font-size: 14px;
}

.copyright-area .copyright-area-content p a {
  color: #7a7e9a;
  display: inline-block;
  font-weight: 600;
}

.copyright-area .copyright-area-content p a:hover {
  color: #209e2e;
}

.copyright-area .copyright-area-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: right;
}

.copyright-area .copyright-area-content ul li {
  display: inline-block;
  color: #7a7e9a;
  font-size: 14px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.copyright-area .copyright-area-content ul li a {
  display: inline-block;
  color: #7a7e9a;
}

.copyright-area .copyright-area-content ul li a:hover {
  color: #209e2e;
}

.copyright-area .copyright-area-content ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -13px;
  width: 1px;
  height: 14px;
  background-color: #209e2e;
}

.copyright-area .copyright-area-content ul li:last-child {
  margin-right: 0;
}

.copyright-area .copyright-area-content ul li:last-child::before {
  display: none;
}

.copyright-area .copyright-area-content ul li:first-child {
  margin-left: 0;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: #ffffff;
  background-color: #209e2e;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  border-radius: 50%;
}

.go-top.active {
  top: 98%;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}

.go-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}

.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #333333;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  border-radius: 50%;
}

.go-top:hover,
.go-top:focus {
  color: #ffffff;
}

.go-top:hover::before,
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
  right: 30px;
  z-index: 99;
  top: 50%;
  position: fixed;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: #ffffff !important;
  background-color: #82b440;
  padding: 8px 20px 8px;
  font-size: 14px;
  font-weight: 500;
  -webkit-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.buy-now-btn:hover {
  background-color: #209e2e;
  color: #ffffff !important;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}



.aiz-sidebar-wrap .aiz-sidebar {
  position: relative;
}

.aiz-sidebar-wrap .aiz-sidebar .cancel-side-bar {
  position: absolute;
  right: 10px;
  top: 15px;
}

.form-group .btn-primary {
  background-color: #124a2f;
  border-color: #ffffff;
  color: var(--white);
}

.form-group .btn-primary:hover {
  background-color: #e62e04 !important;
  border-color: #e62e04 !important;
}

/* .modle-modle {
  width: 500px !important;
  margin-left: ;
} */

.modal-content .modal-body-2 {
  overflow-y: hidden !important;
}

.modal-content-2 {
  margin: 0px !important;
  width: 1500px !important;
}

.modal-content-3 {
  width: 1500px !important;
}

.modle-manage {
  /* width: 700px !important; */
}

.modle-manage .p-3-3 {
  width: 100%;
}

.modal-dialog.modal-90w-w {
  max-width: 100%;
  margin: auto;
}

.modal-90w-w .modal-content {
  width: 1500px;
  margin: auto;
}

.lastWork-2 .fw-600,
.text-truncate {
  color: white;
}

tbody tr td .modle {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background-color: black;
  /* transition: opacity .4s ease , visibility .4s ease , transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden; */
  z-index: 999;
}

.modle .open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.modle img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0px 20px;
  margin: 0 auto;
}

tbody tr td .modle-modle .svg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffff;
  cursor: pointer;
}

.modle.open .bigScreen {
  position: relative;
}

.modle-modle .bigScreen box-icon {
  display: none;
}

.modle.open .bigScreen box-icon {
  fill: red;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #fff;
}


/* //-----modal-----// */

/* .products-modal {
  max-width: 1400px !important;
  margin: auto;
} */

/* bulk import css  */
.alert-pragraph strong {
  font-size: 12px;
}

.alert-pragraph p {
  font-size: 12px;
  color: rgb(0, 64, 133)
}

#bg_categories {
  font-size: 14px;
  color: black;
}

.add_categories {
  background-color: #124a2f !important;

}

.text_categories {
  font-size: 0.875rem !important;
}

.footable-toggle {
  background-color: #F1E0CE !important;
}

.aiz-table.footable>tbody>tr.footable-empty.footableIcon>td:before {
  display: none;
}

.footableIcon td {
  text-align: left !important;

  padding-top: 20px !important;
  font-size: 14px !important;
}

.ferti {
  width: 80px !important;
}

.red-danger {
  font-size: 12px !important;
}

.inp-control {
  color: black !important;
}

.inp-control::placeholder {
  color: black;
}

/* custom css */
.small-text-wraper p {
  margin: 10px 10px 5px;
  font-size: 14px;
  font-family: sans-serif;
  color: black;

}

.heading-wrapper {
  background-color: #e2e5ec;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: ; */
}

.toggle-wrapper span {
  margin-left: 10px;
  font-size: 14px;
  color: black;
  font-family: sans-serif;
  text-transform: capitalize;
  font-weight: 500;
}

.input-search-wrapper {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-count-inp {
  margin-left: 10px;
  width: 50px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
}

.t-align-end {
  text-align: end;
}

.search-inp {
  margin-left: 10px;
  border: 1px solid gray;
  padding: 3px;
  height: 30px;
  border-radius: 5px;
  /* width: 70px; */
}

.order-date-sec {
  margin: 5px;
  padding: 10px 0;
}

.float-right-text {
  text-align: end;
}

.order-creation-d {
  color: black;
  display: flex;
  align-items: end;
  text-align: end;
}

.btn-wrapper {
  padding: 20px 0;
  text-align: end;
}

.btn-wrapper button {
  height: 40px;

}


.preloaderCount {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff73;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 2;
}








/* Registration Start  */
.registrationDetail {
  padding: 10px 0;
  /* background: url("../img/product-detail//fertilizer-application-bg2.jpg") no-repeat left / cover; */
  background: url("../img/product-detail//AIIMS-960x540.png") no-repeat left / cover;
  height: 100vh;
  position: relative;
}

.registrationInfo {
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  box-shadow: 0px 15px 10px -15px #a9a9a9;
  width: 50%;

}

.registerFormField input {
  border-radius: 0;
}

.registerFormField input:focus {
  box-shadow: none;
  border-color: #2874f0;
}

.registerForm,
.registerContent {
  flex: 1;
  padding: 40px 33px;
}

.registerContent {
  background: linear-gradient(to bottom, #2874f0, #1a55b7);
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
}

.contentHeader h3 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 15px;
}

.contentHeader p {
  color: #dbdbdb;
  font-size: 16px;
  line-height: 26px;
}

.registerForm {
  background: #fff;
  border: 1px solid #ededed;
  border-left: 0;
  border-bottom: 0;
}



.label.form-check-label.agreeCheck {
  font-size: 13px;
  color: #333;
}

button.btn.btn-primary.createAccount {
  width: 100%;
  background: #fb641b;
  border-color: #fb641b;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 15px;
  padding: 10px 0;
  letter-spacing: 1px;
}

.joinWith {
  text-align: center;
  margin: 17px 0;
  position: relative;
}

.joinWith:before {
  content: "";
  height: 1px;
  background: #d5d5d5;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.joinWith span {
  display: inline-block;
  background: #fff;
  position: relative;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 13px;
  color: #747474;
}

.connectWith ul {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  column-gap: 10px;
}

.connectWith ul li a {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2ecd5;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  margin-right: 5px;
}

.connectWith ul li a:hover {
  transform: translateY(-6px);
}

.connectWith ul li a.facebook {
  background-color: #3c579e;
}

.connectWith ul li a.twitter {
  background-color: #1cb8ec;
}

.connectWith ul li a.google {
  background-color: #e62833;
}

.alreadyAccount {
  text-align: center;
}

.alreadyAccount {
  margin-top: 20px;
}

.alreadyAccount p {
  margin: 0;
}

.alreadyAccount a {
  text-decoration: underline !important;
}

/* Registration End  */

/* Login Start  */
.form-check.mb-3.forgotInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

label.form-check-label.agreeCheck,
.forgotText a {
  font-size: 14px;
  color: #363636;
}

.forgotText a {
  text-decoration: underline !important;
}

/* Login End  */




.devTols>div {
  margin: 10px 0;
}

.showList {
  border: 1px solid black;
  padding: 10px;
  margin-top: 5px;
  height: 200px;
  overflow: auto;
}

.actionBtn {
  /* border: 1px solid black; */
  border-radius: 6px;
  background-color: #f3f3f3;
  padding: 7px;
  position: absolute;
  top: 20px;
  left: -127px;
  width: 174px;
  z-index: 1;
}

.actionBtns {
  color: #1cb8ec;
  cursor: pointer;
}

.actionBtn>div {
  display: flex;
  align-items: center;
  margin: 3px 0;
}

.rountStr {
  width: 35px;
  background: lightblue;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;

}

.paySection {
  border-radius: 15px;
  padding: 10px;
}

.namesPay>div>div {
  height: 510px;
}

.remveHov:hover {
  border: none !important;
  color: black !important;
}




.profit_loss_main_wrapper {
  border: 1px solid #dbdee0;
  margin: 15px;
}

.profit_loss_main_wrapper .logo_box {
  border-right: 1px solid #dbdee0;
  padding: 6px 12px;
  color: #428bca;
  font-size: 20px;
  font-weight: 700;
}

.profit_loss_main_wrapper .profit_loss_text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 700;
  color: #428bca;
}

.image_date_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image_dwnld_Sec {
  height: 100%;
  padding: 0 12px 0 0;
  align-items: center;
  border-right: 1px solid #dbdee0;
  display: flex;
  color: #428bca;
  font-size: 20px;
}

.image_date_Sec {
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.image_date_Sec span {
  padding: 10px;
}

.prof_loss_heading {
  padding: 10px 20px;
  border-bottom: 1px solid #dbdee0;
}

.proit_loss_banner_sec {
  padding: 15px 30px;
}

.proit_loss_banner_sec .row {
  box-sizing: border-box;
}

.proit_loss_banner_sec .purchase_data {
  padding: 20px 10px;
}

.proit_loss_banner_sec .purchase_data_inner {
  padding: 10px;
  background-color: #fa603d;
}

.proit_loss_banner_sec .heading {
  color: #FFFFFF66;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin: 10px 0;
}

.proit_loss_banner_sec .bold {
  font-size: 30px;
  font-weight: 600;
  color: #ffff;
  text-align: center;
}

.proit_loss_banner_sec .purchases_qty {
  text-align: center;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.proit_loss_banner_sec .paid_tax {
  color: #fff;
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
}

.proit_loss_banner_sec .sales_data {
  padding: 20px 10px;
}

.proit_loss_banner_sec .sales_data .sales_data_inner {
  padding: 10px;
  background-color: #78cd51;
}

.proit_loss_banner_sec .return_data {
  padding: 20px 10px;
}

.proit_loss_banner_sec .return_data .return_data_inner {
  background-color: #ff5454;
  padding: 10px;
}

.proit_loss_banner_sec .expence_data {
  padding: 20px 10px;
}

.proit_loss_banner_sec .expence_data .expence_data_inner {
  background-color: #8e44ad;
  padding: 10px;
}

.proit_loss_banner_sec .prof_loss_bg_blue {
  padding: 20px 10px;
}

.proit_loss_banner_sec .prof_loss_bg_blue .prof_loss_bg_blue_inner {
  background-color: #428bca;
  padding: 10px;
}

.payment_green_box {
  padding: 10px;
}

.payment_green_box .payment_green_box_inner {
  background-color: #16a085;
  padding: 20px 10px;
}

.upper_box_wrapper .bg_blue {
  padding: 10px;
}

.upper_box_wrapper .bg_blue .bg_blue_inner {
  background-color: #428bca;
  padding: 20px 10px;
  border-bottom: 1px solid #fff;
}

.upper_box_wrapper .bg_blue .bg_blue_inner p {
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin: 0 0 5px 0;
}

.table_custom_modal {
  width: 400px;
  height: 400px;
}

.tax_report_tab_nav a {
  padding: 10px 5px;
  margin: 0 3px;
  font-size: 16px;
  /* font-weight: 600; */
  color: black;
  background-color: azure;
}

/* //--delete modal css--// */

.delete_modal_small {
  width: 300px;
  height: 300px;
  margin: 100px auto;
}

/* //tax-report css */

.tax_report_heading_icon_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}

.tax_report_heading_icon_sec .tax_report_heading {
  display: flex;
  /* align-items: center; */
  padding: 5px 10px;
}

.tax_report_heading_icon_sec .tax_report_heading h5 {
  color: #428bca;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
}

.tax_report_heading_icon_sec .icon_box {
  padding-right: 10px;
}

.tax_report_heading_icon_sec .icon_box .tax_icon_list {
  display: flex;
}

.tax_report_heading_icon_sec .icon_box .tax_icon_list li {
  padding: 0 10px;
  border: 1px solid #dee2e6;
}

.tax_customise_report_title {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 10px;
}

.tax_customise_report_title p {
  font-size: 14px;
  color: #333333;
}

.all_taxes_amount_calulation_box {
  padding: 20px;
  display: flex;
}

.all_taxes_amount_calulation_box .sales_amount_box {
  padding: 0 10px;
  width: 33.3%;
  box-sizing: border-box;
}

.all_taxes_amount_calulation_box .sales_amount_box .blue_box {
  width: 100%;
  height: 100%;
  padding: 20px;
  color: #ffffff;
  background-color: #428bca;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.all_taxes_amount_calulation_box .sales_amount_box .blue_box h4 {
  font-size: 28px;
  margin: 10px 0 5px 0;
  color: #ffffff;
  letter-spacing: 1px;
}

.all_taxes_amount_calulation_box .sales_amount_box .blue_box p {
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 10px 0;
}

.prod_tax_amount_box {
  padding: 0 10px;
  width: 33.3%;
  box-sizing: border-box;
}

.prod_tax_amount_box .purpule_box {
  width: 100%;
  height: 100%;
  padding: 20px;
  color: #ffffff;
  background-color: #8e44ad;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.prod_tax_amount_box .purpule_box h4 {
  font-size: 28px;
  margin: 10px 0 5px 0;
  color: #ffffff;
  letter-spacing: 1px;
}

.prod_tax_amount_box .purpule_box p {
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 10px 0;
}

.order_tax_amount_box {
  padding: 0 10px;
  width: 33.3%;
  box-sizing: border-box;
}

.order_tax_amount_box .orange_box {
  width: 100%;
  height: 100%;
  padding: 20px;
  color: #ffffff;
  background-color: #fa603d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.order_tax_amount_box .orange_box h4 {
  font-size: 28px;
  margin: 10px 0 5px 0;
  color: #ffffff;
  letter-spacing: 1px;
}

.order_tax_amount_box .orange_box p {
  font-size: 14px;
  color: #ffffff;
  margin: 0 0 10px 0;
}


.card .card-body {
  overflow: auto !important;
}

.align-center {
  align-items: center;
}

.container-1 {
  width: 700px;
  margin: auto;
}

.assignPickup {
  margin: 14px 0;
}

.language-settings {
  padding: 10px;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* .langu {
  position: relative;
} */
.ql-snow {
  position: relative !important;
}

.language {
  position: absolute;
  /* top: -2px;
  right: 300px */
  top: 42px;
  /* right: 300px; */
  left: 15px;
}

.email-link {
  color: #000;
}

.language .form-select {
  /* border: none !important; */
}

.language .form-select:focus {
  border: none !important;
}

/* .tabbbs {
  border: 1px solid black;
} */

table tbody tr .show-table {
  display: block !important;
}

.mainn .mr-3 {
  margin-right: 10px !important;
}

.mainn {
  align-items: center;
}

.mb-0-0 {
  background-color: gray;
  padding: 10px;
}

.text-md-right-2 {
  width: 100px !important;
}

.btn-info-2 {
  width: 180px;
}

.langu .tab-content {
  margin-top: 60px !important;
}

.innerbuyPackage>div>div {
  height: 310px;
}

.innerbuyPackagepay>div>div {
  height: 510px;
}

.borderColorss {
  border: 1px solid #2E294E;
}



.cartTotals {
  background: #f7f7f7;
  padding: 20px;
  border: 1px dashed #eeeeee;
}

.mainaddStc>div {
  margin: 10px 0;
}

.barcodePrinter {
  flex-wrap: wrap;
}

.barcodePrinter>div {
  margin: 5px 10px;
}

.form-section {
  margin-bottom: 15px;
}

.btn-circle-2 {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100% !important;
}

.icon-icon {
  font-size: 20px;
}

.table-3 td {
  border: 1px solid #b2b4b5;
}

.seach-tab {
  width: 200px;
  float: right;
}

.down-head {
  display: flex;
  align-items: center;
}

.download-sec {
  display: flex;
  align-items: center;
}

.download-sec .down input {
  padding: 10px;
  margin-right: 10px !important;
}

.custo-info {
  margin: 15px 0;
}

.custo-info p {
  font-size: 25px;
  color: black;
  margin: 3px;
}

.custo-info p span {
  margin-left: 6px;
}

.big-font thead th {
  font-size: 20px;
}

.big-font tbody td {
  font-size: 20px;
}